import { Loader } from "@mantine/core";
import * as React from "react";
import { useParams } from "react-router-dom";

import { useProductQuery } from "../../../graphql/graphql";

export const AssignedOffers = () => {
  const { productId } = useParams();

  const { isLoading, data, status } = useProductQuery({
    filters: [{ name: "id", value: productId }],
  });

  if (isLoading) {
    return <Loader />;
  }

  const offers = data.findProduct.offers;

  return (
    <div>
      {offers ? (
        <pre>{JSON.stringify(data.findProduct.offers, null, 2)}</pre>
      ) : (
        "No offers"
      )}
    </div>
  );
};
