import { DndContext } from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import {
  ActionIcon,
  Box,
  Button,
  FileInput,
  FileInputProps,
  Grid,
  Group,
  Image,
  Loader,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { createFormContext } from "@mantine/form";
import { IconLetterCase, IconTrash } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { ConfirmDeleteDialogResponse } from "@zozia/ui";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { BrandCombobox } from "../../../../components/BrandCombobox";
import { useConfirmDeleteDialog } from "../../../../components/ConfirmDeleteDialog";
import {
  UpsertProductDocument,
  useAdminDeleteProductMutation,
  useUpsertProductMutation,
} from "../../../graphql/graphql";
import { AssignedOffers } from "../AssignedOffers/AssignedOffers";
import { SearchableCategoriesInput } from "../ProductsTableActions/SearchableCategoriesInput";
import { SearchableEanInput } from "../ProductsTableActions/SearchableEanInput";
import { ExistingImage } from "./ExistingImage";
import { NewFileImage } from "./NewFileImage";

const TextEditor = React.lazy(() => import("@zozia/ui/TextEditor"));

const print = (query: string) => query.replace(/(\r\n|\n|\r)/gm, "");

interface FormValues {
  id?: string;
  name: string;
  ean: string;
  description: string;
  meta: {
    brandId: string | null;
    categoryId: string | null;
  };
  images?: (File | { url: string })[];
}

const [FormProvider, useFormContext, useForm] = createFormContext<FormValues>();

export { useFormContext };

type ProductUpsertFormProps = {
  initialValues?: Omit<FormValues, "meta"> & { brand: any; category: any };
};

export const ProductUpsertForm = ({
  initialValues = {
    name: "",
    ean: "",
    description: "",
    brand: {},
    category: {},
    images: [],
  },
}: ProductUpsertFormProps) => {
  const { productId } = useParams();
  const nav = useNavigate();
  const queryClient = useQueryClient();

  const form = useForm({
    initialValues: {
      ...initialValues,
      images: initialValues?.images?.filter((img) => img.url),
      meta: {
        brandId: initialValues?.brand?.id ?? null,
        categoryId: initialValues?.category?.id ?? null,
      },
    },
  });
  const confirmDelete = useConfirmDeleteDialog();

  const { mutateAsync } = useUpsertProductMutation();
  const { mutate: deleteProductMutate } = useAdminDeleteProductMutation();

  const isCreatingProduct = productId === "new";
  return (
    <>
      <FormProvider form={form}>
        <Box mx="auto">
          <Group>
            <Title>
              {isCreatingProduct ? "Tworzenie produktu" : "Edytowanie produktu"}
            </Title>
            {!isCreatingProduct ? (
              <ActionIcon
                onClick={async () => {
                  const response = await confirmDelete();
                  const input = {
                    productId: form.values.id,
                  };
                  console.log({ input });
                  if (response === ConfirmDeleteDialogResponse.NO) {
                    console.log("delete");
                  }

                  deleteProductMutate({
                    input,
                  });
                }}
              >
                <IconTrash />
              </ActionIcon>
            ) : null}
          </Group>
          <form
            onSubmit={form.onSubmit(async (values) => {
              console.log(values);
              const fd = new FormData();
              fd.append(
                "operations",
                JSON.stringify({
                  query: print(UpsertProductDocument),
                  variables: {
                    input: {
                      id: values.id,
                      name: values.name,
                      ean: values.ean,
                      categoryId: values.meta.categoryId,
                      brandId: values.meta.brandId,
                      description: values.description,
                      images:
                        values.images.filter((file) => file instanceof File) ||
                        [],
                      existingImages: values.images
                        .filter((file) => file instanceof File === false)
                        .map((file) => ({ id: file.id, order: file.order })),
                    },
                  },
                }),
              );

              fd.append(
                "map",
                `{ ${values.images
                  .filter((file) => file instanceof File)
                  .map(
                    (_, index) =>
                      `"${index}": ["variables.input.images.${index}.file"]`,
                  )
                  .join(", ")} }`,
              );
              values.images
                .filter((file) => file instanceof File)
                .forEach((file, index) => {
                  fd.append(index.toString(), file);
                });

              await mutateAsync({ input: fd });

              await queryClient.invalidateQueries([
                "Products.infinite",
                {
                  input: {
                    cursor: null,
                    filters: [
                      { name: "categoryId", value: [] },
                      { name: "brandId", value: [] },
                      { name: "name/ean", value: [] },
                    ],
                    orderBy: [
                      { descriptor: { sort: "DESC" }, field: "updatedAt" },
                    ],
                  },
                },
              ]);

              nav("/collections/products");
            })}
          >
            <Group>
              <TextInput
                label="Nazwa"
                placeholder="Coca Cola 1L"
                className="flex-grow"
                {...form.getInputProps("name")}
              />
              <ActionIcon
                onClick={() => {
                  form.setFieldValue(
                    "name",
                    form.values.name.charAt(0).toUpperCase() +
                      form.values.name.slice(1).toLocaleLowerCase(),
                  );
                }}
              >
                <IconLetterCase />
              </ActionIcon>
            </Group>

            <SearchableEanInput
              label="EAN"
              placeholder="59..."
              onChange={({ value: [ean] }) => {
                form.setFieldValue("ean", ean);
              }}
              defaultValue={form.values.ean}
            />

            <BrandCombobox
              key={form.values.meta.brandId}
              defaultValue={form.values.meta.brandId}
              onChange={(brandId: string) => {
                form.setFieldValue(`meta.brandId`, brandId);
              }}
            />

            <SearchableCategoriesInput
              label="Kategoria"
              placeholder="Soki"
              onChange={({ value: [categoryId] }) => {
                form.setFieldValue("meta.categoryId", categoryId);
              }}
              multi={false}
              defaultValue={[form.values.meta.categoryId]}
            />

            <FileInput
              label="Zdjęcia"
              multiple
              valueComponent={({ value }) => (
                <ValueComponent
                  value={value}
                  onOrderChange={(files) => {
                    console.log({ files });

                    form.setValues((prev) => ({
                      ...prev,
                      images: files,
                    }));
                  }}
                />
              )}
              value={form.values.images || []}
              onChange={(files) => {
                form.setValues((prev) => ({
                  ...prev,
                  images: getUnique(
                    (prev.images || []).concat(files),
                    (a) => a.id,
                  ),
                }));
              }}
            />

            <Text size="sm" fw={500}>
              Opis produktu
            </Text>
            <React.Suspense fallback={<Loader />}>
              <TextEditor
                defaultValue={form.values.description}
                onChange={({ value }) => {
                  form.setFieldValue("description", value);
                }}
              />
            </React.Suspense>

            <Button type="submit" mt="md">
              Zapisz
            </Button>
          </form>
        </Box>
      </FormProvider>
      {productId === "new" ? null : (
        <Grid.Col span={6}>
          <div>Additional Info</div>
          <AssignedOffers />
        </Grid.Col>
      )}
    </>
  );
};

function getUnique(arr, predicate) {
  const mapObj = new Map();

  arr.forEach((v) => {
    const prevValue = mapObj.get(v.name);
    if (!prevValue || predicate(prevValue)) {
      mapObj.set(v.name, v);
    }
  });
  return [...mapObj.values()];
}

const ValueComponent: FileInputProps["valueComponent"] = ({
  value,
  onOrderChange,
}) => {
  const { productId } = useParams<{ productId: string }>();

  const [items] = React.useState(
    value
      .filter((file) => file instanceof File === false)
      .map(({ id }) => id) as string[],
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);

      console.log({ items });
      const newOrders = arrayMove(items, oldIndex, newIndex);
      console.log({ newOrders });
      onOrderChange(
        newOrders.map((id, idx) => {
          return {
            ...value.find((file) => file.id === id),
            order: idx,
          };
        }),
      );
    }
  }

  if (Array.isArray(value)) {
    return (
      <Group py="xs">
        {value
          .filter((file) => file instanceof File)
          .map((file) => (
            <NewFileImage file={file} key={file.name} />
          ))}
        <DndContext onDragEnd={handleDragEnd}>
          <SortableContext
            items={
              items.filter(
                (file) => file instanceof File === false,
              ) as unknown as {
                id: string;
              }[]
            }
          >
            {items
              .map((id) => value.find((file) => file.id === id))
              .map((file, idx) => (
                <ExistingImage file={file} order={idx} productId={productId} />
              ))}
          </SortableContext>
        </DndContext>
      </Group>
    );
  }

  return <Image maw={240} radius="md" alt="Uploaded image" src={value.url} />;
};
