import { useQuery, useInfiniteQuery, useMutation, UseQueryOptions, UseInfiniteQueryOptions, UseMutationOptions } from '@tanstack/react-query';
import { useFetchData } from '../../lib/fetcher';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  File: { input: any; output: any; }
  Json: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type AcceptLocationRequestResponse = {
  __typename?: 'AcceptLocationRequestResponse';
  error?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type Ad = {
  __typename?: 'Ad';
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  location?: Maybe<Location>;
  publisher?: Maybe<User>;
  story?: Maybe<Story>;
  type: AdType;
  updatedAt: Scalars['String']['output'];
};

export type AdType =
  | 'Story';

export type AddCartItemInput = {
  locationId: Scalars['ID']['input'];
  offerId: Scalars['ID']['input'];
};

export type AddTaxInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  rate: Scalars['Int']['input'];
};

export type AddressType =
  | 'BillAddress'
  | 'DeliveryAddress'
  | 'InvoiceAddress';

export type AdminAcceptProductSuggestionInput = {
  acceptedSuggestions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  data?: InputMaybe<Scalars['Json']['input']>;
  productRequestUpdateId: Scalars['ID']['input'];
  rejectedSuggestions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type AdminAcceptProductSuggestionResult = {
  __typename?: 'AdminAcceptProductSuggestionResult';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type AdminBrand = BaseBrand & {
  __typename?: 'AdminBrand';
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  meta?: Maybe<Scalars['Json']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  names?: Maybe<Array<Maybe<BrandName>>>;
  productCount?: Maybe<Scalars['Int']['output']>;
  products?: Maybe<Array<Maybe<Product>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type AdminBusinessOwner = {
  __typename?: 'AdminBusinessOwner';
  avatar?: Maybe<Scalars['String']['output']>;
  businessId: Scalars['ID']['output'];
  email?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['ID']['output']>;
};

export type AdminBusinessOwnersInput = {
  searchName?: InputMaybe<Scalars['String']['input']>;
};

export type AdminContextualDiscovery = {
  __typename?: 'AdminContextualDiscovery';
  active?: Maybe<Scalars['Boolean']['output']>;
  categories?: Maybe<Array<Maybe<AdminContextualDiscoveryCategory>>>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AdminContextualDiscoveryCategory = {
  __typename?: 'AdminContextualDiscoveryCategory';
  category?: Maybe<LocationCategory>;
  categoryId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  locations?: Maybe<Array<Maybe<Location>>>;
};

export type AdminContextualDiscoveryCategoryLocation = {
  __typename?: 'AdminContextualDiscoveryCategoryLocation';
  contextualDiscoveryCategoryId: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
};

export type AdminContextualDiscoveryInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
};

export type AdminCreateMerchantInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  taxId: Scalars['String']['input'];
};

export type AdminDeleteProductInput = {
  productId: Scalars['ID']['input'];
};

export type AdminDisconnectContextualDiscoveryCategoryResult = {
  __typename?: 'AdminDisconnectContextualDiscoveryCategoryResult';
  id: Scalars['ID']['output'];
};

export type AdminFindProductDuplicateInput = {
  field: Scalars['String']['input'];
};

export type AdminLocation = {
  __typename?: 'AdminLocation';
  business?: Maybe<Business>;
  location: Location;
};

export type AdminLocationsSearchInput = {
  contextualDiscoveryId: Scalars['ID']['input'];
};

export type AdminPaginatedProductsToVerify = {
  __typename?: 'AdminPaginatedProductsToVerify';
  cursor?: Maybe<Scalars['ID']['output']>;
  data?: Maybe<Array<Maybe<AdminProductToVerify>>>;
};

export type AdminProductToVerify = {
  __typename?: 'AdminProductToVerify';
  createdAt?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['Json']['output']>;
  ean?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  product?: Maybe<Product>;
  requester?: Maybe<User>;
  status?: Maybe<Scalars['String']['output']>;
  suggestions?: Maybe<Array<Maybe<ProductSuggestion>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type AdminProductsToVerifyInput = {
  cursor?: InputMaybe<Scalars['ID']['input']>;
  filters?: InputMaybe<Array<InputMaybe<AdminProductsToVerifyInputFilter>>>;
};

export type AdminProductsToVerifyInputFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpsertBrandInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  meta?: InputMaybe<Scalars['Json']['input']>;
  name: Scalars['String']['input'];
};

export type AdminUpsertBrandNameInput = {
  brandNameId?: InputMaybe<Scalars['ID']['input']>;
  manufacturerId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type AdminUpsertContextualDiscoveryCategoryInput = {
  categoryId: Scalars['ID']['input'];
  contextualDiscoveryCategoryId?: InputMaybe<Scalars['ID']['input']>;
  contextualDiscoveryId: Scalars['ID']['input'];
};

export type AdminUpsertContextualDiscoveryCategoryLocationInput = {
  contextualDiscoveryCategoryId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
};

export type AdminUpsertEmailInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  template: Scalars['String']['input'];
};

export type AdminUpsertLocationCategoryInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type AdminUpsertLocationOwnerInput = {
  businessId?: InputMaybe<Scalars['ID']['input']>;
  locationId: Scalars['ID']['input'];
};

export type AdminUpsertLocationOwnerResult = {
  __typename?: 'AdminUpsertLocationOwnerResult';
  ok: Scalars['Boolean']['output'];
};

export type AdminUser = {
  __typename?: 'AdminUser';
  avatar?: Maybe<Scalars['String']['output']>;
  businessId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type AdsInput = {
  locationId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type BaseBrand = {
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  meta?: Maybe<Scalars['Json']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  names?: Maybe<Array<Maybe<BrandName>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type Brand = BaseBrand & {
  __typename?: 'Brand';
  counts?: Maybe<BrandNestedCount>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  meta?: Maybe<Scalars['Json']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  names?: Maybe<Array<Maybe<BrandName>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type BrandInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BrandInputFrom =
  | 'BIR'
  | 'Form'
  | 'GS1';

export type BrandName = {
  __typename?: 'BrandName';
  id: Scalars['ID']['output'];
  manufacturerId?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type BrandNestedCount = {
  __typename?: 'BrandNestedCount';
  products?: Maybe<Scalars['Int']['output']>;
};

export type BrandsFromCategoryInput = {
  categoryId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
};

export type BrandsInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
};

export type Business = {
  __typename?: 'Business';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  employees?: Maybe<Array<Maybe<Employee>>>;
  id?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<Scalars['Json']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<User>;
  paymentProfiles?: Maybe<Array<Maybe<PaymentProfile>>>;
  paymentProviderAccount?: Maybe<Scalars['Json']['output']>;
  regon?: Maybe<Scalars['String']['output']>;
  taxId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type BusinessSetupProcess = {
  __typename?: 'BusinessSetupProcess';
  currentStep: Scalars['Int']['output'];
  isCompleted: Scalars['Boolean']['output'];
  steps: Array<BusinessSetupStep>;
};

export type BusinessSetupStep = {
  __typename?: 'BusinessSetupStep';
  data: Scalars['Json']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isCompleted: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  step: Scalars['Int']['output'];
};

export type CartItem = {
  __typename?: 'CartItem';
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  offer: Offer;
  offerId: Scalars['ID']['output'];
  price: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  replacement?: Maybe<Offer>;
  replacementId?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type CategoriesFromParentInput = {
  locationId: Scalars['ID']['input'];
  parentCategoryId: Scalars['ID']['input'];
};

export type CategoriesFromProductsResult = {
  __typename?: 'CategoriesFromProductsResult';
  id: Scalars['ID']['output'];
  meta?: Maybe<CategoryMeta>;
  name: Scalars['String']['output'];
  productsCount: Scalars['Int']['output'];
};

export type Category = {
  __typename?: 'Category';
  children?: Maybe<Array<Maybe<Category>>>;
  counts?: Maybe<CategoryNestedCount>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<Scalars['Json']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Category>;
  parentId?: Maybe<Scalars['ID']['output']>;
  taxId?: Maybe<Scalars['ID']['output']>;
  tree?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type CategoryInput = {
  meta?: InputMaybe<Scalars['Json']['input']>;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type CategoryMeta = {
  __typename?: 'CategoryMeta';
  icon?: Maybe<Scalars['String']['output']>;
};

export type CategoryNestedCount = {
  __typename?: 'CategoryNestedCount';
  products?: Maybe<Scalars['Int']['output']>;
};

export type CategoryWithProducts = {
  __typename?: 'CategoryWithProducts';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<Category>;
  parentId?: Maybe<Scalars['ID']['output']>;
  products?: Maybe<Array<Maybe<Product>>>;
};

export type City = {
  __typename?: 'City';
  boundingbox?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  lat: Scalars['String']['output'];
  lon: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CityListInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type CompanyInfo = {
  __typename?: 'CompanyInfo';
  city?: Maybe<Scalars['String']['output']>;
  community?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  flaatNo?: Maybe<Scalars['String']['output']>;
  houseNo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  regon?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  voivodeship?: Maybe<Scalars['String']['output']>;
};

export type CompleteCartProcessData = {
  __typename?: 'CompleteCartProcessData';
  orderId: Scalars['ID']['output'];
};

export type CompleteCartProcessInput = {
  cartId: Scalars['ID']['input'];
  deliveryAddressId: Scalars['ID']['input'];
};

export type CompleteCartProcessResponse = {
  __typename?: 'CompleteCartProcessResponse';
  data?: Maybe<CompleteCartProcessData>;
};

export type CountProductsInput = {
  filters?: InputMaybe<Array<InputMaybe<ProductFilter>>>;
};

export type Counts = {
  __typename?: 'Counts';
  location?: Maybe<CountsResult>;
  offerts?: Maybe<CountsResult>;
  product?: Maybe<CountsResult>;
  productRequestUpdate?: Maybe<CountsResult>;
  shopping?: Maybe<CountsResult>;
  shoppingItem?: Maybe<CountsResult>;
  user?: Maybe<CountsResult>;
};

export type CountsResult = {
  __typename?: 'CountsResult';
  count: Scalars['Int']['output'];
  diff: Array<Maybe<MonthCountResult>>;
};

export type CreateBrandInput = {
  inputFrom?: InputMaybe<BrandInputFrom>;
  meta?: InputMaybe<Scalars['Json']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateLocationsFromBoundaryInput = {
  boundary?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>>>;
  location: LatLong;
  radius: Scalars['Int']['input'];
};

export type CreatePaymentIntentInput = {
  currency?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  shoppingCartId: Scalars['ID']['input'];
};

export type CreateReelInput = {
  source: Scalars['String']['input'];
  storyId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

export type DecreaseCartItemInput = {
  cartItemId: Scalars['ID']['input'];
};

export type DeleteMerchantWarehousePackItemResult = {
  __typename?: 'DeleteMerchantWarehousePackItemResult';
  id: Scalars['ID']['output'];
};

export type DeleteProductImageInput = {
  imageId: Scalars['ID']['input'];
  productId: Scalars['ID']['input'];
};

export type DeleteProductImageResult = {
  __typename?: 'DeleteProductImageResult';
  deletedImageId?: Maybe<Scalars['ID']['output']>;
};

export type DeleteProductResult = {
  __typename?: 'DeleteProductResult';
  id: Scalars['ID']['output'];
};

export type DeleteWarehousePackItemImageInput = {
  imageId: Scalars['ID']['input'];
  warehousePackItemId: Scalars['ID']['input'];
};

export type DeliveryOption = {
  __typename?: 'DeliveryOption';
  currency: Scalars['String']['output'];
  deliveryHours: Scalars['Json']['output'];
  deliveryPrice: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  minOrderAmount?: Maybe<Scalars['Int']['output']>;
  radius?: Maybe<Scalars['Int']['output']>;
  type: DeliveryType;
};

export type DeliveryOptionCopy = {
  __typename?: 'DeliveryOptionCopy';
  deliveryPrice: Scalars['Int']['output'];
  description: Scalars['String']['output'];
  radius?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};

export type DeliveryPackingOption = {
  __typename?: 'DeliveryPackingOption';
  createdAt?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locationId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  packingPrice: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type DeliveryPackingOptionCopy = {
  __typename?: 'DeliveryPackingOptionCopy';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  locationId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  packingPrice: Scalars['Int']['output'];
};

export type DeliveryPricing = {
  __typename?: 'DeliveryPricing';
  deliveryPrice: Scalars['Int']['output'];
};

export type DeliveryPricingInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  locationId: Scalars['ID']['input'];
};

export type DeliveryType =
  | 'DeliveryByOwner'
  | 'Pickup';

export type Discovery = {
  __typename?: 'Discovery';
  categories?: Maybe<Array<Maybe<DiscoveryCategory>>>;
  id?: Maybe<Scalars['ID']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type DiscoveryCategory = {
  __typename?: 'DiscoveryCategory';
  id: Scalars['ID']['output'];
  locations?: Maybe<Array<Maybe<Location>>>;
  name: Scalars['String']['output'];
};

export type DiscoveryCity = {
  __typename?: 'DiscoveryCity';
  distance?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type DiscoveryLocationInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type Email = {
  __typename?: 'Email';
  history?: Maybe<Array<Maybe<EmailHistory>>>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  subject: Scalars['String']['output'];
  template: Scalars['String']['output'];
};

export type EmailHistory = {
  __typename?: 'EmailHistory';
  changedBy: User;
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  prevSubject: Scalars['String']['output'];
  prevTemplate: Scalars['String']['output'];
};

export type Employee = {
  __typename?: 'Employee';
  employee: User;
  id: Scalars['ID']['output'];
  invite?: Maybe<EmployeeInvite>;
  roles?: Maybe<Array<Maybe<EmployeeRole>>>;
};

export type EmployeeInvite = {
  __typename?: 'EmployeeInvite';
  status?: Maybe<Scalars['String']['output']>;
};

export type EmployeeRole = {
  __typename?: 'EmployeeRole';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  rule?: Maybe<EmployeeRule>;
};

export type EmployeeRule = {
  __typename?: 'EmployeeRule';
  action?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  condition?: Maybe<Scalars['Json']['output']>;
  subject?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type ExistingImageInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
};

export type FavouritesProductsInput = {
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
};

export type FindNearestPlaceForProductInput = {
  location: LatLong;
  productId: Scalars['ID']['input'];
};

export type Image = {
  __typename?: 'Image';
  blurhash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type IncreaseCartItemInput = {
  cartItemId: Scalars['ID']['input'];
};

export type LatLong = {
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
};

export type ListOffersByCategoryInput = {
  categoryId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
};

export type ListOffersInput = {
  location?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  productId?: InputMaybe<Scalars['String']['input']>;
  radius?: InputMaybe<Scalars['Int']['input']>;
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Scalars['String']['output']>;
  businessEmail?: Maybe<Scalars['String']['output']>;
  coverPhoto?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  flags?: Maybe<Scalars['Json']['output']>;
  hasOwner?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Image>>>;
  isFavourite?: Maybe<Scalars['Boolean']['output']>;
  isPendingInvite?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  logo?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  openingHours?: Maybe<Scalars['Json']['output']>;
  placeId?: Maybe<Scalars['ID']['output']>;
  products?: Maybe<Array<Maybe<Product>>>;
  websites?: Maybe<Array<Maybe<Website>>>;
};


export type LocationImagesArgs = {
  size?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type LocationCategory = {
  __typename?: 'LocationCategory';
  distance?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type LocationInput = {
  address: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  placeId?: InputMaybe<Scalars['ID']['input']>;
};

export type LocationPaymentMethod = {
  __typename?: 'LocationPaymentMethod';
  deliveryOptionId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<LocationPaymentMethodType>;
};

export type LocationPaymentMethodType =
  | 'ApplePay'
  | 'Blik'
  | 'Card'
  | 'Cash'
  | 'GooglePay'
  | 'Online';

export type LocationRequest = {
  __typename?: 'LocationRequest';
  additionalInfo?: Maybe<Scalars['Json']['output']>;
  business?: Maybe<Business>;
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  location?: Maybe<Location>;
  status?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type LocationRequestInput = {
  business: Scalars['Json']['input'];
  locationId: Scalars['ID']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type LocationRequestsInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
};

export type LocationsForProductInput = {
  location: LatLong;
  productId: Scalars['ID']['input'];
  radius: Scalars['Float']['input'];
};

export type LocationsInput = {
  boundary?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>>>;
  cursor?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  location: LatLong;
  radius: Scalars['Int']['input'];
};

export type Merchant = {
  __typename?: 'Merchant';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type MerchantActiveCartsInput = {
  locationId: Scalars['ID']['input'];
};

export type MerchantAddDeliveryPackingOptionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  packingPrice: Scalars['Int']['input'];
};

export type MerchantAssignOffertToVariantInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  offerId: Scalars['ID']['input'];
  parentOfferId: Scalars['ID']['input'];
  variantId: Scalars['ID']['input'];
};

export type MerchantAssignProductsToLocationInput = {
  warehousePackId: Scalars['ID']['input'];
};

export type MerchantAssignProductsToLocationResult = {
  __typename?: 'MerchantAssignProductsToLocationResult';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type MerchantChangeOrderStatusInput = {
  estimatedDeliveryTime?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  status: OrderStatus;
};

export type MerchantCompletePaymentProfileInput = {
  name: Scalars['String']['input'];
};

export type MerchantConfirmAdditionalLocationOptionsInput = {
  businessSetupStepId: Scalars['ID']['input'];
};

export type MerchantConfirmBusinesProfileInput = {
  businessSetupStepId: Scalars['ID']['input'];
  taxId?: InputMaybe<Scalars['String']['input']>;
};

export type MerchantCreateLocationInput = {
  address: Scalars['String']['input'];
  businessEmail?: InputMaybe<Scalars['String']['input']>;
  businessSetupStepId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  openingHours: Scalars['String']['input'];
};

export type MerchantCreateOfferVariantInput = {
  offerId: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};

export type MerchantCreatePaymentProviderAccountInput = {
  test?: InputMaybe<Scalars['String']['input']>;
};

export type MerchantDeleteCategoryShowcaseResponse = {
  __typename?: 'MerchantDeleteCategoryShowcaseResponse';
  success: Scalars['Boolean']['output'];
};

export type MerchantDeleteOfferInput = {
  offerId: Scalars['ID']['input'];
};

export type MerchantDelivery = {
  __typename?: 'MerchantDelivery';
  deliveryHours?: Maybe<Scalars['Json']['output']>;
  id: Scalars['ID']['output'];
  minOrderAmount?: Maybe<Scalars['Int']['output']>;
  options?: Maybe<Array<Maybe<MerchantDeliveryOption>>>;
};

export type MerchantDeliveryOption = {
  __typename?: 'MerchantDeliveryOption';
  description: Scalars['String']['output'];
  geojson?: Maybe<Scalars['Json']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type MerchantDeliveryOptionInput = {
  geom?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  type: Scalars['String']['input'];
};

export type MerchantDisconnectOffertFromVariantInput = {
  offerId: Scalars['ID']['input'];
  variantId: Scalars['ID']['input'];
};

export type MerchantDisconnectProductFromCategoryShowcaseInput = {
  productId: Scalars['ID']['input'];
};

export type MerchantDisconnectProductFromCategoryShowcaseResponse = {
  __typename?: 'MerchantDisconnectProductFromCategoryShowcaseResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type MerchantInviteEmployeeInput = {
  email: Scalars['String']['input'];
  locationId: Scalars['String']['input'];
};

export type MerchantLocation = {
  __typename?: 'MerchantLocation';
  address?: Maybe<Scalars['String']['output']>;
  employeePermissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  flags?: Maybe<Scalars['Json']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Image>>>;
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  name: Scalars['String']['output'];
};

export type MerchantLocationImageInput = {
  file: Scalars['File']['input'];
  size: Scalars['String']['input'];
};

export type MerchantOnboardingStatus = {
  __typename?: 'MerchantOnboardingStatus';
  currentStep: Scalars['Int']['output'];
};

export type MerchantOrdersInput = {
  locationId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<OrderStatus>;
};

export type MerchantTogglePriceHiddenResult = {
  __typename?: 'MerchantTogglePriceHiddenResult';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type MerchantUpdateDeliveryPackingOptionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  locationId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  packingPrice?: InputMaybe<Scalars['Int']['input']>;
};

export type MerchantUpdateLocationInput = {
  businessEmail?: InputMaybe<Scalars['String']['input']>;
  coverImage?: InputMaybe<MerchantLocationImageInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  locationId: Scalars['ID']['input'];
  logoImage?: InputMaybe<MerchantLocationImageInput>;
  openingHours?: InputMaybe<Scalars['String']['input']>;
  websites?: InputMaybe<Array<InputMaybe<WebsiteInput>>>;
};

export type MerchantUpdateOfferInput = {
  active: Scalars['Boolean']['input'];
  offerId: Scalars['ID']['input'];
  price: Scalars['Int']['input'];
};

export type MerchantUpdatePaymentProviderAccountInput = {
  test?: InputMaybe<Scalars['String']['input']>;
};

export type MerchantUpsertCategoryShowcaseInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['Int']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
};

export type MerchantUpsertCategoryShowcaseResponse = {
  __typename?: 'MerchantUpsertCategoryShowcaseResponse';
  success: Scalars['Boolean']['output'];
};

export type MerchantUpsertDeliveryOptionInput = {
  active: Scalars['Boolean']['input'];
  currency: Scalars['String']['input'];
  deliveryOptionId?: InputMaybe<Scalars['ID']['input']>;
  deliveryOptionType: DeliveryType;
  deliveryPrice: Scalars['Int']['input'];
  locationId: Scalars['ID']['input'];
  radius?: InputMaybe<Scalars['Int']['input']>;
};

export type MerchantUpsertDeliveryPackingOptionInput = {
  active: Scalars['Boolean']['input'];
  deliveryPackingOptionId?: InputMaybe<Scalars['ID']['input']>;
  description: Scalars['String']['input'];
  locationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  packingPrice: Scalars['Int']['input'];
};

export type MerchantUpsertLocationInput = {
  address: Scalars['String']['input'];
  businessEmail?: InputMaybe<Scalars['String']['input']>;
  businessSetupStepId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  name: Scalars['String']['input'];
  openingHours: Scalars['String']['input'];
};

export type MerchantUpsertPaymentMethodInput = {
  active: Scalars['Boolean']['input'];
  locationId: Scalars['ID']['input'];
  locationPaymentMethodId?: InputMaybe<Scalars['ID']['input']>;
  paymentType: LocationPaymentMethodType;
};

export type MerchantUpsertWarehousePackItemInput = {
  currency?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  ean?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['File']['input']>>>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
  meta: MerchantUpsertWarehousePackItemMetaInput;
  name: Scalars['String']['input'];
  price: Scalars['Int']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  taxRate: Scalars['Int']['input'];
  warehousePackId?: InputMaybe<Scalars['ID']['input']>;
  withoutEan: Scalars['Boolean']['input'];
};

export type MerchantUpsertWarehousePackItemMetaInput = {
  brandId: Scalars['ID']['input'];
  categoryId: Scalars['ID']['input'];
};

export type MerchanttoggleActiveShoppingResult = {
  __typename?: 'MerchanttoggleActiveShoppingResult';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type MonthCountResult = {
  __typename?: 'MonthCountResult';
  count: Scalars['BigInt']['output'];
  date?: Maybe<Scalars['DateTime']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptLocationRequest?: Maybe<AcceptLocationRequestResponse>;
  addCartItem?: Maybe<Scalars['Boolean']['output']>;
  addUserBillAddress: UserAddress;
  addUserInvoiceAddress: UserAddress;
  adminAcceptProductSuggestion?: Maybe<AdminAcceptProductSuggestionResult>;
  adminAddTax: Tax;
  adminCreateMerchant: Merchant;
  adminDeleteBrand?: Maybe<AdminBrand>;
  adminDeleteBrandName?: Maybe<BrandName>;
  adminDeleteEmail?: Maybe<Email>;
  adminDeleteProduct?: Maybe<Product>;
  adminDisconnectContextualDiscoveryCategory?: Maybe<AdminDisconnectContextualDiscoveryCategoryResult>;
  adminDisconnectContextualDiscoveryCategoryLocation?: Maybe<AdminContextualDiscoveryCategoryLocation>;
  adminRejectProductSuggestion?: Maybe<AdminAcceptProductSuggestionResult>;
  adminToggleContextualDiscoveryActive?: Maybe<Scalars['Boolean']['output']>;
  adminUpsertBrand?: Maybe<AdminBrand>;
  adminUpsertBrandName?: Maybe<BrandName>;
  adminUpsertContextualDiscoveryCategory?: Maybe<AdminContextualDiscoveryCategory>;
  adminUpsertContextualDiscoveryCategoryLocation?: Maybe<AdminContextualDiscoveryCategoryLocation>;
  adminUpsertEmail?: Maybe<Email>;
  adminUpsertLocationCategory: Scalars['ID']['output'];
  adminUpsertLocationOwner?: Maybe<AdminUpsertLocationOwnerResult>;
  completeCartProcess: CompleteCartProcessResponse;
  createBrand: Brand;
  createCategory?: Maybe<Category>;
  createLocation?: Maybe<Location>;
  createLocationRequest?: Maybe<LocationRequest>;
  createLocationsFromBoundary?: Maybe<Location>;
  createPaymentIntent?: Maybe<PaymentIntent>;
  createProduct?: Maybe<Product>;
  createReel: Reel;
  decreaseCartItem: Scalars['Boolean']['output'];
  deleteBrand: Brand;
  deleteCategory?: Maybe<Category>;
  deleteProduct: DeleteProductResult;
  deleteProductImage: DeleteProductImageResult;
  deleteWarehousePackItemImage: DeleteProductImageResult;
  employeeAcceptInvite: Scalars['String']['output'];
  increaseCartItem: Scalars['Boolean']['output'];
  merchantActivateAccount?: Maybe<Scalars['Boolean']['output']>;
  merchantAddDeliveryPackingOption?: Maybe<DeliveryPackingOption>;
  merchantAssignOffertToVariant: OfferVariant;
  merchantAssignProductsToLocation: MerchantAssignProductsToLocationResult;
  merchantChangeOrderStatus?: Maybe<Order>;
  merchantCompletePaymentProfile?: Maybe<MerchantOnboardingStatus>;
  merchantConfirmAdditionalLocationOptions?: Maybe<MerchantOnboardingStatus>;
  merchantConfirmBusinessProfile?: Maybe<MerchantOnboardingStatus>;
  merchantConfirmPhoneNumber?: Maybe<MerchantOnboardingStatus>;
  merchantCreateLocation?: Maybe<Location>;
  merchantCreateOfferVariant: OfferVariant;
  merchantCreatePaymentProviderAccount?: Maybe<Scalars['Json']['output']>;
  merchantCreateWarehousePack?: Maybe<WarehousePack>;
  merchantDeleteCategoryShowcase: MerchantDeleteCategoryShowcaseResponse;
  merchantDeleteDeliveryOption?: Maybe<Scalars['Boolean']['output']>;
  merchantDeleteDeliveryPackingOption?: Maybe<Scalars['Boolean']['output']>;
  merchantDeleteOffer?: Maybe<Scalars['Boolean']['output']>;
  merchantDeletePaymentMethod?: Maybe<Scalars['Boolean']['output']>;
  merchantDeletePaymentProviderAccount?: Maybe<Scalars['Json']['output']>;
  merchantDeleteWarehousePack: Scalars['ID']['output'];
  merchantDeleteWarehousePackItem: DeleteMerchantWarehousePackItemResult;
  merchantDisconnectOffertFromVariant: OfferVariantToOffer;
  merchantDisconnectProductFromCategoryShowcase?: Maybe<MerchantDisconnectProductFromCategoryShowcaseResponse>;
  merchantInviteEmployee: Scalars['String']['output'];
  merchantMarkProductAsActive?: Maybe<Scalars['Int']['output']>;
  merchantMarkProductAsDeactivated?: Maybe<Scalars['Int']['output']>;
  merchantToggleActiveSale: MerchanttoggleActiveShoppingResult;
  merchantTogglePriceHidden: MerchantTogglePriceHiddenResult;
  merchantUpdateDeliveryPackingOption?: Maybe<DeliveryPackingOption>;
  merchantUpdateLocation?: Maybe<Location>;
  merchantUpdateOffer?: Maybe<Offer>;
  merchantUpdatePaymentProviderAccount?: Maybe<Scalars['Json']['output']>;
  merchantUpsertCategoryShowcase?: Maybe<MerchantUpsertCategoryShowcaseResponse>;
  merchantUpsertDeliveryOption?: Maybe<Scalars['Boolean']['output']>;
  merchantUpsertDeliveryPackingOption?: Maybe<Scalars['Boolean']['output']>;
  merchantUpsertLocation?: Maybe<Location>;
  merchantUpsertPaymentMethod?: Maybe<Scalars['Boolean']['output']>;
  merchantUpsertWarehousePackItem: WarehouseProduct;
  notificationMarkAs?: Maybe<Notification>;
  rejectLocationRequest?: Maybe<RejectLocationRequestResponse>;
  removeCart: Scalars['Boolean']['output'];
  removeCartItem?: Maybe<Scalars['Boolean']['output']>;
  sendVerificationCode: Scalars['Boolean']['output'];
  setCartDeliveryAddress: UserAddressResponse;
  setCartGroupDeliveryOption: Scalars['Boolean']['output'];
  setCartGroupDeliveryPackingOption: Scalars['Boolean']['output'];
  setCartGroupPaymentMethod: Scalars['Boolean']['output'];
  setCartItemNote: Scalars['Boolean']['output'];
  setCartItemReplacement: Scalars['Boolean']['output'];
  setPhoneNumber: Scalars['Boolean']['output'];
  toggleFavouriteLocation?: Maybe<Location>;
  tryToCreateProductFromOnline?: Maybe<Product>;
  updateBrand: UpdateBrandResult;
  updateCategory?: Maybe<Category>;
  updateProduct: UpdateProductResult;
  updateReel: Reel;
  upsertProduct: UpdateProductResult;
  upsertUserDeliveryAddress: UserAddressResponse;
  upsertWarehousePack?: Maybe<UpsertWarehousePackResult>;
};


export type MutationAcceptLocationRequestArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAddCartItemArgs = {
  input: AddCartItemInput;
};


export type MutationAddUserBillAddressArgs = {
  input: UserBillAddressInput;
};


export type MutationAddUserInvoiceAddressArgs = {
  input: UserInvoiceAddressInput;
};


export type MutationAdminAcceptProductSuggestionArgs = {
  input?: InputMaybe<AdminAcceptProductSuggestionInput>;
};


export type MutationAdminAddTaxArgs = {
  input: AddTaxInput;
};


export type MutationAdminCreateMerchantArgs = {
  input: AdminCreateMerchantInput;
};


export type MutationAdminDeleteBrandArgs = {
  brandId: Scalars['ID']['input'];
};


export type MutationAdminDeleteBrandNameArgs = {
  brandNameId: Scalars['ID']['input'];
};


export type MutationAdminDeleteEmailArgs = {
  emailTemplateId: Scalars['String']['input'];
};


export type MutationAdminDeleteProductArgs = {
  input: AdminDeleteProductInput;
};


export type MutationAdminDisconnectContextualDiscoveryCategoryArgs = {
  contextualDiscoveryCategoryId: Scalars['ID']['input'];
};


export type MutationAdminDisconnectContextualDiscoveryCategoryLocationArgs = {
  input?: InputMaybe<AdminUpsertContextualDiscoveryCategoryLocationInput>;
};


export type MutationAdminRejectProductSuggestionArgs = {
  productRequestUpdateId: Scalars['ID']['input'];
};


export type MutationAdminToggleContextualDiscoveryActiveArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAdminUpsertBrandArgs = {
  input: AdminUpsertBrandInput;
};


export type MutationAdminUpsertBrandNameArgs = {
  input: AdminUpsertBrandNameInput;
};


export type MutationAdminUpsertContextualDiscoveryCategoryArgs = {
  input?: InputMaybe<AdminUpsertContextualDiscoveryCategoryInput>;
};


export type MutationAdminUpsertContextualDiscoveryCategoryLocationArgs = {
  input?: InputMaybe<AdminUpsertContextualDiscoveryCategoryLocationInput>;
};


export type MutationAdminUpsertEmailArgs = {
  input: AdminUpsertEmailInput;
};


export type MutationAdminUpsertLocationCategoryArgs = {
  input: AdminUpsertLocationCategoryInput;
};


export type MutationAdminUpsertLocationOwnerArgs = {
  input: AdminUpsertLocationOwnerInput;
};


export type MutationCompleteCartProcessArgs = {
  input: CompleteCartProcessInput;
};


export type MutationCreateBrandArgs = {
  input: CreateBrandInput;
};


export type MutationCreateCategoryArgs = {
  input?: InputMaybe<CategoryInput>;
};


export type MutationCreateLocationArgs = {
  input?: InputMaybe<LocationInput>;
};


export type MutationCreateLocationRequestArgs = {
  input: LocationRequestInput;
};


export type MutationCreateLocationsFromBoundaryArgs = {
  input?: InputMaybe<CreateLocationsFromBoundaryInput>;
};


export type MutationCreatePaymentIntentArgs = {
  input: CreatePaymentIntentInput;
};


export type MutationCreateProductArgs = {
  input?: InputMaybe<ProductInput>;
};


export type MutationCreateReelArgs = {
  input: CreateReelInput;
};


export type MutationDecreaseCartItemArgs = {
  input: DecreaseCartItemInput;
};


export type MutationDeleteBrandArgs = {
  brandId: Scalars['ID']['input'];
};


export type MutationDeleteCategoryArgs = {
  categoryId: Scalars['ID']['input'];
};


export type MutationDeleteProductArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteProductImageArgs = {
  input: DeleteProductImageInput;
};


export type MutationDeleteWarehousePackItemImageArgs = {
  input: DeleteWarehousePackItemImageInput;
};


export type MutationEmployeeAcceptInviteArgs = {
  locationId: Scalars['String']['input'];
};


export type MutationIncreaseCartItemArgs = {
  input: IncreaseCartItemInput;
};


export type MutationMerchantAddDeliveryPackingOptionArgs = {
  input: MerchantAddDeliveryPackingOptionInput;
};


export type MutationMerchantAssignOffertToVariantArgs = {
  input: MerchantAssignOffertToVariantInput;
};


export type MutationMerchantAssignProductsToLocationArgs = {
  input?: InputMaybe<MerchantAssignProductsToLocationInput>;
};


export type MutationMerchantChangeOrderStatusArgs = {
  input: MerchantChangeOrderStatusInput;
};


export type MutationMerchantCompletePaymentProfileArgs = {
  input: MerchantCompletePaymentProfileInput;
};


export type MutationMerchantCreateLocationArgs = {
  input: MerchantCreateLocationInput;
};


export type MutationMerchantCreateOfferVariantArgs = {
  input: MerchantCreateOfferVariantInput;
};


export type MutationMerchantCreatePaymentProviderAccountArgs = {
  input: MerchantCreatePaymentProviderAccountInput;
};


export type MutationMerchantCreateWarehousePackArgs = {
  locationId: Scalars['String']['input'];
};


export type MutationMerchantDeleteCategoryShowcaseArgs = {
  categoryShowcaseId: Scalars['ID']['input'];
};


export type MutationMerchantDeleteDeliveryOptionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMerchantDeleteDeliveryPackingOptionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMerchantDeleteOfferArgs = {
  input: MerchantDeleteOfferInput;
};


export type MutationMerchantDeletePaymentMethodArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMerchantDeleteWarehousePackArgs = {
  warehousePackId: Scalars['ID']['input'];
};


export type MutationMerchantDeleteWarehousePackItemArgs = {
  warehousePackItemId: Scalars['ID']['input'];
};


export type MutationMerchantDisconnectOffertFromVariantArgs = {
  input: MerchantDisconnectOffertFromVariantInput;
};


export type MutationMerchantDisconnectProductFromCategoryShowcaseArgs = {
  input?: InputMaybe<MerchantDisconnectProductFromCategoryShowcaseInput>;
};


export type MutationMerchantInviteEmployeeArgs = {
  input: MerchantInviteEmployeeInput;
};


export type MutationMerchantMarkProductAsActiveArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationMerchantMarkProductAsDeactivatedArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  locationId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationMerchantToggleActiveSaleArgs = {
  locationId: Scalars['ID']['input'];
};


export type MutationMerchantTogglePriceHiddenArgs = {
  locationId: Scalars['ID']['input'];
};


export type MutationMerchantUpdateDeliveryPackingOptionArgs = {
  input: MerchantUpdateDeliveryPackingOptionInput;
};


export type MutationMerchantUpdateLocationArgs = {
  input?: InputMaybe<MerchantUpdateLocationInput>;
};


export type MutationMerchantUpdateOfferArgs = {
  input: MerchantUpdateOfferInput;
};


export type MutationMerchantUpdatePaymentProviderAccountArgs = {
  input: MerchantUpdatePaymentProviderAccountInput;
};


export type MutationMerchantUpsertCategoryShowcaseArgs = {
  input?: InputMaybe<MerchantUpsertCategoryShowcaseInput>;
};


export type MutationMerchantUpsertDeliveryOptionArgs = {
  input: MerchantUpsertDeliveryOptionInput;
};


export type MutationMerchantUpsertDeliveryPackingOptionArgs = {
  input: MerchantUpsertDeliveryPackingOptionInput;
};


export type MutationMerchantUpsertLocationArgs = {
  input: MerchantUpsertLocationInput;
};


export type MutationMerchantUpsertPaymentMethodArgs = {
  input: MerchantUpsertPaymentMethodInput;
};


export type MutationMerchantUpsertWarehousePackItemArgs = {
  input: MerchantUpsertWarehousePackItemInput;
  mode: Scalars['String']['input'];
};


export type MutationNotificationMarkAsArgs = {
  input: NotificationMarkAsInput;
};


export type MutationRejectLocationRequestArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveCartArgs = {
  cartId: Scalars['ID']['input'];
};


export type MutationRemoveCartItemArgs = {
  input: RemoveCartItemInput;
};


export type MutationSendVerificationCodeArgs = {
  phoneNumber: Scalars['String']['input'];
};


export type MutationSetCartDeliveryAddressArgs = {
  input: SetCartDeliveryAddressInput;
};


export type MutationSetCartGroupDeliveryOptionArgs = {
  input: SetCartGroupDeliveryOptionInput;
};


export type MutationSetCartGroupDeliveryPackingOptionArgs = {
  input: SetCartGroupDeliveryPackingOptionInput;
};


export type MutationSetCartGroupPaymentMethodArgs = {
  input: SetCartGroupPaymentMethodInput;
};


export type MutationSetCartItemNoteArgs = {
  cartItemId: Scalars['ID']['input'];
  note: Scalars['String']['input'];
};


export type MutationSetCartItemReplacementArgs = {
  input: SetCartItemReplacementInput;
};


export type MutationSetPhoneNumberArgs = {
  input: SetPhoneNumberInput;
};


export type MutationToggleFavouriteLocationArgs = {
  locationId: Scalars['ID']['input'];
};


export type MutationTryToCreateProductFromOnlineArgs = {
  gtin: Scalars['String']['input'];
};


export type MutationUpdateBrandArgs = {
  input: UpdateBrandInput;
};


export type MutationUpdateCategoryArgs = {
  category: UpdateCategoryInput;
};


export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};


export type MutationUpdateReelArgs = {
  input: UpdateReelInput;
};


export type MutationUpsertProductArgs = {
  input: UpsertProductInput;
};


export type MutationUpsertUserDeliveryAddressArgs = {
  input: UpsertUserDeliveryAddressInput;
};


export type MutationUpsertWarehousePackArgs = {
  input?: InputMaybe<UpsertWarehousePackInput>;
};

export type NearOffer = {
  __typename?: 'NearOffer';
  categoryId: Scalars['ID']['output'];
  distance?: Maybe<Scalars['Float']['output']>;
  location?: Maybe<Location>;
  locationId: Scalars['ID']['output'];
  offer?: Maybe<Offer>;
  offerId: Scalars['ID']['output'];
  product?: Maybe<Product>;
  productId: Scalars['ID']['output'];
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['String']['output']>;
  readed?: Maybe<Scalars['Boolean']['output']>;
  recipient?: Maybe<Recipient>;
  sender?: Maybe<Sender>;
  title?: Maybe<Scalars['String']['output']>;
};

export type NotificationConfig = {
  __typename?: 'NotificationConfig';
  config?: Maybe<NotificationConfigData>;
  id: Scalars['ID']['output'];
};

export type NotificationConfigData = {
  __typename?: 'NotificationConfigData';
  android?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  ios?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  web?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type NotificationMarkAsInput = {
  id: Scalars['ID']['input'];
  readed: Scalars['Boolean']['input'];
};

export type Offer = {
  __typename?: 'Offer';
  active?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  lastPrice?: Maybe<Price>;
  location?: Maybe<Location>;
  locationId?: Maybe<Scalars['ID']['output']>;
  prices?: Maybe<Array<Maybe<Price>>>;
  product?: Maybe<Product>;
  productId?: Maybe<Scalars['ID']['output']>;
  showcaseCategoryId?: Maybe<Scalars['ID']['output']>;
  taxRate?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  variants?: Maybe<Array<Maybe<OfferVariantGroup>>>;
};

export type OfferVariant = {
  __typename?: 'OfferVariant';
  id: Scalars['ID']['output'];
  offerId: Scalars['ID']['output'];
  type: Scalars['String']['output'];
  variant: Scalars['String']['output'];
};

export type OfferVariantGroup = {
  __typename?: 'OfferVariantGroup';
  id: Scalars['ID']['output'];
  items?: Maybe<Array<Maybe<OfferVariant>>>;
  type: Scalars['String']['output'];
};

export type OfferVariantToOffer = {
  __typename?: 'OfferVariantToOffer';
  label?: Maybe<Scalars['String']['output']>;
  offerId: Scalars['ID']['output'];
  variantId: Scalars['ID']['output'];
};

export type OfferVariantType = {
  __typename?: 'OfferVariantType';
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
};

export type OffertsFromCategoryInput = {
  categoryId: Scalars['ID']['input'];
  locationId: Scalars['ID']['input'];
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type Order = {
  __typename?: 'Order';
  createdAt?: Maybe<Scalars['String']['output']>;
  deliveryAddress?: Maybe<UserAddress>;
  deliveryOption?: Maybe<DeliveryOption>;
  id: Scalars['ID']['output'];
  items?: Maybe<Array<Maybe<OrderItem>>>;
  location?: Maybe<Location>;
  packingOption?: Maybe<PackingOption>;
  paymentMethod?: Maybe<LocationPaymentMethod>;
  paymentStatus: PaymentStatus;
  purchaser?: Maybe<Purchaser>;
  status: OrderStatus;
  trackDelivery?: Maybe<TrackDelivery>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type OrderBy = {
  descriptor?: InputMaybe<OrderByDescriptor>;
  field?: InputMaybe<Scalars['String']['input']>;
};

export type OrderByDescriptor = {
  sort?: InputMaybe<OrderByDirection>;
};

export type OrderByDirection =
  | 'ASC'
  | 'DESC';

export type OrderItem = {
  __typename?: 'OrderItem';
  createdAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  offer?: Maybe<OrderItemOffer>;
  price?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type OrderItemOffer = {
  __typename?: 'OrderItemOffer';
  currency?: Maybe<Scalars['String']['output']>;
  offerId: Scalars['ID']['output'];
  price?: Maybe<Scalars['Int']['output']>;
  product?: Maybe<OrderItemOfferProduct>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type OrderItemOfferProduct = {
  __typename?: 'OrderItemOfferProduct';
  description?: Maybe<Scalars['String']['output']>;
  ean?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  primaryImage?: Maybe<Image>;
};

export type OrderStatus =
  | 'Accepted'
  | 'Canceled'
  | 'Delivered'
  | 'Delivering'
  | 'Disputed'
  | 'Done'
  | 'Ordered'
  | 'Processing'
  | 'ReadyToPickUp'
  | 'Rejected';

export type PackingOption = {
  __typename?: 'PackingOption';
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  packingPrice?: Maybe<Scalars['Int']['output']>;
};

export type PaginatedAdminContextualDiscovery = {
  __typename?: 'PaginatedAdminContextualDiscovery';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<AdminContextualDiscovery>>>;
};

export type PaginatedBrands = {
  __typename?: 'PaginatedBrands';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<Brand>>>;
};

export type PaginatedBusiness = {
  __typename?: 'PaginatedBusiness';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<Business>>>;
};

export type PaginatedFilterInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type PaginatedInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<InputMaybe<PaginatedFilterInput>>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
};

export type PaginatedLocation = {
  __typename?: 'PaginatedLocation';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<Location>>>;
};

export type PaginatedLocationRequests = {
  __typename?: 'PaginatedLocationRequests';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<LocationRequest>>>;
};

export type PaginatedOfferts = {
  __typename?: 'PaginatedOfferts';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<Offer>>>;
};

export type PaginatedProducts = {
  __typename?: 'PaginatedProducts';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<Product>>>;
};

export type PaginatedTaxes = {
  __typename?: 'PaginatedTaxes';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<Tax>>>;
};

export type PaginatedUsers = {
  __typename?: 'PaginatedUsers';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<AdminUser>>>;
};

export type PaginatedWarehouseProducts = {
  __typename?: 'PaginatedWarehouseProducts';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<WarehouseProductData>>>;
};

export type Payment = {
  __typename?: 'Payment';
  id: Scalars['ID']['output'];
  metadata?: Maybe<PaymentMetadata>;
};

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  customer: Scalars['String']['output'];
  ephemeralKey: Scalars['String']['output'];
  paymentIntent: Scalars['String']['output'];
};

export type PaymentMetadata = {
  __typename?: 'PaymentMetadata';
  customerId?: Maybe<Scalars['String']['output']>;
  ephemeralKeySecret?: Maybe<Scalars['String']['output']>;
  paymentIntentClientSecret?: Maybe<Scalars['String']['output']>;
  paymentIntentId?: Maybe<Scalars['String']['output']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  active?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  locationId: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: LocationPaymentMethodType;
};

export type PaymentProfile = {
  __typename?: 'PaymentProfile';
  active?: Maybe<Scalars['Boolean']['output']>;
  businessId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  locationId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  providerId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type PaymentStatus =
  | 'Failed'
  | 'Paid'
  | 'Pending'
  | 'Refunded';

export type PopularLocationsInput = {
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
};

export type PopularSearchQuery = {
  __typename?: 'PopularSearchQuery';
  id: Scalars['ID']['output'];
  q: Scalars['String']['output'];
};

export type Price = {
  __typename?: 'Price';
  currency?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type Product = {
  __typename?: 'Product';
  active?: Maybe<Scalars['Boolean']['output']>;
  brand?: Maybe<Brand>;
  brandId?: Maybe<Scalars['String']['output']>;
  brandName?: Maybe<BrandName>;
  brandNameId?: Maybe<Scalars['String']['output']>;
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  ean?: Maybe<Scalars['String']['output']>;
  existing?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Image>>>;
  lastPrice?: Maybe<Price>;
  locations?: Maybe<Array<Maybe<Location>>>;
  name: Scalars['String']['output'];
  offers?: Maybe<Array<Maybe<Offer>>>;
  priceHistory?: Maybe<Array<Maybe<Price>>>;
  primaryImage?: Maybe<Image>;
  showcaseCategory?: Maybe<ShowcaseCategory>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  variants?: Maybe<Array<Maybe<OfferVariantGroup>>>;
};


export type ProductLastPriceArgs = {
  locationId?: InputMaybe<Scalars['ID']['input']>;
};


export type ProductPriceHistoryArgs = {
  input?: InputMaybe<ProductWithFiltersInput>;
};


export type ProductVariantsArgs = {
  locationId: Scalars['ID']['input'];
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ProductFilter = {
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type ProductFilter2 = {
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ProductInput = {
  description: Scalars['String']['input'];
  ean?: InputMaybe<Scalars['String']['input']>;
  image: Scalars['String']['input'];
  name: Scalars['String']['input'];
  price: Scalars['Int']['input'];
};

export type ProductSuggestion = {
  __typename?: 'ProductSuggestion';
  createdAt?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Scalars['Json']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  rejectedMessage?: Maybe<Scalars['String']['output']>;
  rejectedType?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type ProductWithFiltersInput = {
  filters?: InputMaybe<Array<InputMaybe<ProductFilter>>>;
};

export type ProductsFromCategoryInput = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  cursor?: InputMaybe<Scalars['ID']['input']>;
  filters?: InputMaybe<Array<InputMaybe<ProductFilter>>>;
  locationId: Scalars['ID']['input'];
  parentCategoryId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProductsInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<InputMaybe<ProductFilter>>>;
};

export type ProductsInput2 = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Array<InputMaybe<ProductFilter2>>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
};

export type Purchaser = {
  __typename?: 'Purchaser';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  ad?: Maybe<Ad>;
  adminBrand?: Maybe<AdminBrand>;
  adminBrandNames?: Maybe<Array<Maybe<BrandName>>>;
  adminBrands: PaginatedBrands;
  adminBusiness?: Maybe<Business>;
  adminBusinessOwners?: Maybe<Array<Maybe<AdminBusinessOwner>>>;
  adminBusinesses: PaginatedBusiness;
  adminContextualDiscoveries?: Maybe<PaginatedAdminContextualDiscovery>;
  adminContextualDiscovery?: Maybe<AdminContextualDiscovery>;
  adminEmail?: Maybe<Email>;
  adminFindProductDuplicate?: Maybe<Array<Maybe<Product>>>;
  adminListEmails?: Maybe<Array<Maybe<Email>>>;
  adminLocation?: Maybe<AdminLocation>;
  adminLocations: PaginatedLocation;
  adminLocationsSearch?: Maybe<Array<Maybe<Location>>>;
  adminProductToVerify?: Maybe<AdminProductToVerify>;
  adminProducts: PaginatedProducts;
  adminProductsToVerify?: Maybe<AdminPaginatedProductsToVerify>;
  adminProductsToVerifyCount: Scalars['Int']['output'];
  adminTaxes: PaginatedTaxes;
  adminUser?: Maybe<AdminUser>;
  adminUsers?: Maybe<PaginatedUsers>;
  ads?: Maybe<Array<Maybe<Ad>>>;
  availableTaxes?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  brand?: Maybe<Brand>;
  brands: PaginatedBrands;
  brandsFromCategory: Array<Maybe<CategoriesFromProductsResult>>;
  calculateShoppingCart?: Maybe<Scalars['Json']['output']>;
  categoriesFromParent: Array<Maybe<CategoriesFromProductsResult>>;
  categoriesFromProducts: Array<Maybe<CategoriesFromProductsResult>>;
  category: Category;
  cityList?: Maybe<Array<Maybe<DiscoveryCity>>>;
  countProducts: Scalars['Int']['output'];
  counts?: Maybe<Counts>;
  deliveryPricing?: Maybe<DeliveryPricing>;
  discoveryLocation?: Maybe<Discovery>;
  favouritesProducts: Array<Product>;
  findNearestCity?: Maybe<City>;
  findNearestPlaceForProduct?: Maybe<Array<Maybe<Location>>>;
  findProduct?: Maybe<Product>;
  listNearOffers?: Maybe<Array<Maybe<NearOffer>>>;
  listOffersByCategory?: Maybe<Array<Maybe<Offer>>>;
  location?: Maybe<Location>;
  locationDeliveryOptions: Array<Maybe<DeliveryOption>>;
  locationDeliveryPackingOptions: Array<Maybe<DeliveryPackingOption>>;
  locationPaymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
  locationRequests?: Maybe<PaginatedLocationRequests>;
  locations?: Maybe<PaginatedLocation>;
  locationsForProduct: Array<Location>;
  locationsFromBoundary?: Maybe<Array<Location>>;
  me: User;
  merchantActiveCarts?: Maybe<Scalars['Json']['output']>;
  merchantBusinessFirstLocation: Location;
  merchantBusinessSetupProcess: BusinessSetupProcess;
  merchantCategoriesFromProducts?: Maybe<Array<Maybe<Category>>>;
  merchantDelivery?: Maybe<MerchantDelivery>;
  merchantDeliveryOption?: Maybe<MerchantDeliveryOption>;
  merchantEmployee?: Maybe<Employee>;
  merchantGetDeliveryPackingOptions?: Maybe<Array<Maybe<DeliveryPackingOption>>>;
  merchantIsOfferExists?: Maybe<Offer>;
  merchantListEmployees?: Maybe<Array<Maybe<Employee>>>;
  merchantLocation?: Maybe<MerchantLocation>;
  merchantLocationCategory?: Maybe<Array<Maybe<LocationCategory>>>;
  merchantMyBusiness: Business;
  merchantOffer?: Maybe<Offer>;
  merchantOfferVariantTypes: Array<OfferVariantType>;
  merchantOrder?: Maybe<Order>;
  merchantOrders?: Maybe<Array<Maybe<Order>>>;
  merchantProduct?: Maybe<Product>;
  merchantProducts: PaginatedProducts;
  merchantWarehousePack?: Maybe<WarehousePack>;
  merchantWarehousePackItem?: Maybe<WarehouseProduct>;
  merchantWarehousePacks?: Maybe<Array<Maybe<WarehousePack>>>;
  merchantWarehouseProducts: PaginatedWarehouseProducts;
  myAddresses: Array<UserAddress>;
  myLocations: Array<Location>;
  myOrders?: Maybe<Array<Maybe<Order>>>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  offer?: Maybe<Offer>;
  offersShowcase: Array<Maybe<ShowcaseCategory>>;
  offertsFromCategory: PaginatedOfferts;
  order?: Maybe<Order>;
  popularLocations: Array<Location>;
  popularSearchQuery?: Maybe<Array<Maybe<PopularSearchQuery>>>;
  product?: Maybe<Product>;
  productCategories: Array<Category>;
  products: PaginatedProducts;
  productsByLocationId: Array<CategoryWithProducts>;
  productsFromCategory: PaginatedOfferts;
  search?: Maybe<SearchResultResponse>;
  searchBrandOnline?: Maybe<Scalars['Json']['output']>;
  searchProducts: SearchProduct;
  setupIntent: SetupIntent;
  shoppingCarts?: Maybe<Array<Maybe<ShoppingCart>>>;
  stories?: Maybe<Array<Maybe<Ad>>>;
  tryToFindProductOnline?: Maybe<Scalars['Json']['output']>;
  validateTaxId?: Maybe<CompanyInfo>;
};


export type QueryAdArgs = {
  adId: Scalars['ID']['input'];
};


export type QueryAdminBrandArgs = {
  brandId: Scalars['ID']['input'];
};


export type QueryAdminBrandsArgs = {
  input?: InputMaybe<BrandsInput>;
};


export type QueryAdminBusinessArgs = {
  businessId: Scalars['ID']['input'];
};


export type QueryAdminBusinessOwnersArgs = {
  input: AdminBusinessOwnersInput;
};


export type QueryAdminBusinessesArgs = {
  input?: InputMaybe<PaginatedInput>;
};


export type QueryAdminContextualDiscoveriesArgs = {
  input?: InputMaybe<AdminContextualDiscoveryInput>;
};


export type QueryAdminContextualDiscoveryArgs = {
  contextualDiscoveryId: Scalars['ID']['input'];
};


export type QueryAdminEmailArgs = {
  emailTemplateId: Scalars['String']['input'];
};


export type QueryAdminFindProductDuplicateArgs = {
  input: AdminFindProductDuplicateInput;
};


export type QueryAdminLocationArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryAdminLocationsArgs = {
  input?: InputMaybe<PaginatedInput>;
};


export type QueryAdminLocationsSearchArgs = {
  input?: InputMaybe<AdminLocationsSearchInput>;
};


export type QueryAdminProductToVerifyArgs = {
  productSuggestionId: Scalars['ID']['input'];
};


export type QueryAdminProductsArgs = {
  input?: InputMaybe<ProductsInput2>;
};


export type QueryAdminProductsToVerifyArgs = {
  input?: InputMaybe<AdminProductsToVerifyInput>;
};


export type QueryAdminTaxesArgs = {
  input?: InputMaybe<PaginatedInput>;
};


export type QueryAdminUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAdminUsersArgs = {
  input: PaginatedInput;
};


export type QueryAdsArgs = {
  input: AdsInput;
};


export type QueryBrandArgs = {
  brandId: Scalars['ID']['input'];
};


export type QueryBrandsArgs = {
  input?: InputMaybe<BrandsInput>;
};


export type QueryBrandsFromCategoryArgs = {
  input: BrandsFromCategoryInput;
};


export type QueryCalculateShoppingCartArgs = {
  shoppingCartId: Scalars['ID']['input'];
};


export type QueryCategoriesFromParentArgs = {
  input: CategoriesFromParentInput;
};


export type QueryCategoriesFromProductsArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryCategoryArgs = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCityListArgs = {
  input?: InputMaybe<CityListInput>;
};


export type QueryCountProductsArgs = {
  input?: InputMaybe<CountProductsInput>;
};


export type QueryDeliveryPricingArgs = {
  input: DeliveryPricingInput;
};


export type QueryDiscoveryLocationArgs = {
  input: DiscoveryLocationInput;
};


export type QueryFavouritesProductsArgs = {
  input?: InputMaybe<FavouritesProductsInput>;
};


export type QueryFindNearestCityArgs = {
  lat: Scalars['Float']['input'];
  lon: Scalars['Float']['input'];
};


export type QueryFindNearestPlaceForProductArgs = {
  input: FindNearestPlaceForProductInput;
};


export type QueryFindProductArgs = {
  filters?: InputMaybe<Array<InputMaybe<ProductFilter>>>;
};


export type QueryListNearOffersArgs = {
  input: ListOffersInput;
};


export type QueryListOffersByCategoryArgs = {
  input: ListOffersByCategoryInput;
};


export type QueryLocationArgs = {
  id: Scalars['ID']['input'];
  includeProducts?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryLocationDeliveryOptionsArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryLocationDeliveryPackingOptionsArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryLocationPaymentMethodsArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryLocationRequestsArgs = {
  input?: InputMaybe<LocationRequestsInput>;
};


export type QueryLocationsArgs = {
  input: LocationsInput;
};


export type QueryLocationsForProductArgs = {
  input: LocationsForProductInput;
};


export type QueryLocationsFromBoundaryArgs = {
  boundary: Array<InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>>;
  location: Array<InputMaybe<Scalars['Float']['input']>>;
};


export type QueryMerchantActiveCartsArgs = {
  input: MerchantActiveCartsInput;
};


export type QueryMerchantCategoriesFromProductsArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryMerchantDeliveryArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryMerchantDeliveryOptionArgs = {
  deliveryOptionId: Scalars['ID']['input'];
};


export type QueryMerchantEmployeeArgs = {
  employeeId: Scalars['ID']['input'];
};


export type QueryMerchantGetDeliveryPackingOptionsArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryMerchantIsOfferExistsArgs = {
  ean: Scalars['String']['input'];
  locationId: Scalars['ID']['input'];
};


export type QueryMerchantListEmployeesArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryMerchantLocationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMerchantOfferArgs = {
  offerId: Scalars['ID']['input'];
};


export type QueryMerchantOfferVariantTypesArgs = {
  offerId: Scalars['ID']['input'];
};


export type QueryMerchantOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMerchantOrdersArgs = {
  input: MerchantOrdersInput;
};


export type QueryMerchantProductArgs = {
  input?: InputMaybe<ProductWithFiltersInput>;
};


export type QueryMerchantProductsArgs = {
  input?: InputMaybe<ProductsInput>;
};


export type QueryMerchantWarehousePackArgs = {
  warehousePackId: Scalars['ID']['input'];
};


export type QueryMerchantWarehousePackItemArgs = {
  warehousePackItemId: Scalars['ID']['input'];
};


export type QueryMerchantWarehousePacksArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryMerchantWarehouseProductsArgs = {
  input?: InputMaybe<ProductsInput>;
};


export type QueryMyAddressesArgs = {
  type: AddressType;
};


export type QueryOfferArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOffersShowcaseArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryOffertsFromCategoryArgs = {
  input: OffertsFromCategoryInput;
};


export type QueryOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPopularLocationsArgs = {
  input?: InputMaybe<PopularLocationsInput>;
};


export type QueryProductArgs = {
  id: Scalars['ID']['input'];
  locationId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryProductsArgs = {
  input?: InputMaybe<ProductsInput2>;
};


export type QueryProductsByLocationIdArgs = {
  locationId: Scalars['ID']['input'];
};


export type QueryProductsFromCategoryArgs = {
  input: ProductsFromCategoryInput;
  locationId?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerySearchArgs = {
  input: SearchInput;
};


export type QuerySearchBrandOnlineArgs = {
  input: SearchBrandOnlineInput;
};


export type QuerySearchProductsArgs = {
  input?: InputMaybe<SearchProductsInput>;
};


export type QueryStoriesArgs = {
  input?: InputMaybe<StoriesInput>;
};


export type QueryTryToFindProductOnlineArgs = {
  input?: InputMaybe<TryToFindProductOnlineInput>;
};


export type QueryValidateTaxIdArgs = {
  taxId: Scalars['String']['input'];
};

export type Recipient = {
  __typename?: 'Recipient';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Reel = {
  __typename?: 'Reel';
  addons?: Maybe<Scalars['Json']['output']>;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  source: Scalars['String']['output'];
  story?: Maybe<Story>;
  type: ReelType;
  updatedAt: Scalars['String']['output'];
};

export type ReelType =
  | 'Image'
  | 'Video';

export type RejectLocationRequestResponse = {
  __typename?: 'RejectLocationRequestResponse';
  error?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type RemoveCartItemInput = {
  cartItemId: Scalars['ID']['input'];
};

export type ReplacementType =
  | 'OwnerReplacement'
  | 'Refund'
  | 'SelfReplacement';

export type ResponseStatus =
  | 'Error'
  | 'Success';

export type SearchBrandOnlineInput = {
  country: Scalars['String']['input'];
  taxId: Scalars['String']['input'];
};

export type SearchInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  pos: Array<InputMaybe<Scalars['Float']['input']>>;
  text: Scalars['String']['input'];
};

export type SearchProduct = {
  __typename?: 'SearchProduct';
  cursor?: Maybe<Scalars['String']['output']>;
  data?: Maybe<Array<Maybe<Product>>>;
};

export type SearchProductsInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SearchResult = {
  __typename?: 'SearchResult';
  categoryId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  locationId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  offerCount?: Maybe<Scalars['Int']['output']>;
};

export type SearchResultResponse = {
  __typename?: 'SearchResultResponse';
  page?: Maybe<Scalars['Int']['output']>;
  results?: Maybe<Array<Maybe<SearchResult>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type Sender = {
  __typename?: 'Sender';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type SetCartDeliveryAddressInput = {
  cartId: Scalars['ID']['input'];
  deliveryAddressId?: InputMaybe<Scalars['ID']['input']>;
  oneTimeDeliveryAddress?: InputMaybe<Scalars['Json']['input']>;
};

export type SetCartGroupDeliveryOptionInput = {
  cartGroupId: Scalars['ID']['input'];
  deliveryOptionId?: InputMaybe<Scalars['ID']['input']>;
};

export type SetCartGroupDeliveryPackingOptionInput = {
  cartGroupId: Scalars['ID']['input'];
  deliveryPackingOptionId: Scalars['ID']['input'];
};

export type SetCartGroupPaymentMethodInput = {
  cartGroupId: Scalars['ID']['input'];
  paymentMethodId: Scalars['ID']['input'];
};

export type SetCartItemReplacementInput = {
  cartItemId: Scalars['ID']['input'];
  replacementId?: InputMaybe<Scalars['ID']['input']>;
  replacementType?: InputMaybe<ReplacementType>;
};

export type SetPhoneNumberInput = {
  phoneNumber: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
};

export type SetupIntent = {
  __typename?: 'SetupIntent';
  customer: Scalars['String']['output'];
  ephemeralKey: Scalars['String']['output'];
  setupIntent: Scalars['String']['output'];
};

export type ShoppingCart = {
  __typename?: 'ShoppingCart';
  createdAt: Scalars['String']['output'];
  customerId?: Maybe<Scalars['String']['output']>;
  deliveryAddress?: Maybe<UserAddress>;
  deliveryAddressCopy?: Maybe<UserAddressCopy>;
  deliveryOption?: Maybe<DeliveryOption>;
  deliveryOptionCopy?: Maybe<DeliveryOptionCopy>;
  deliveryPackingOption?: Maybe<DeliveryPackingOption>;
  deliveryPackingOptionCopy?: Maybe<DeliveryPackingOptionCopy>;
  ephemeralKey?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  items: Array<CartItem>;
  location: Location;
  payment?: Maybe<Payment>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodId?: Maybe<Scalars['ID']['output']>;
  stripeIntentClientSecret?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type ShowcaseCategory = {
  __typename?: 'ShowcaseCategory';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  offers: Array<Maybe<Offer>>;
  order?: Maybe<Scalars['Int']['output']>;
  targetCategoryId?: Maybe<Scalars['ID']['output']>;
};

export type StoriesInput = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
};

export type Story = {
  __typename?: 'Story';
  ad?: Maybe<Ad>;
  adId?: Maybe<Scalars['ID']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  reels?: Maybe<Array<Maybe<Reel>>>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type Tax = {
  __typename?: 'Tax';
  createdAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  rate: Scalars['Int']['output'];
  updatedAt: Scalars['String']['output'];
};

export type TrackDelivery = {
  __typename?: 'TrackDelivery';
  estimatedDeliveryTime?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type TryToFindProductOnlineInput = {
  createOnFind?: InputMaybe<Scalars['Boolean']['input']>;
  gtin: Scalars['String']['input'];
};

export type UpdateBrandInput = {
  id: Scalars['ID']['input'];
  meta?: InputMaybe<Scalars['Json']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateBrandResult = {
  __typename?: 'UpdateBrandResult';
  id: Scalars['ID']['output'];
};

export type UpdateCategoryInput = {
  id: Scalars['ID']['input'];
  meta?: InputMaybe<Scalars['Json']['input']>;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
  taxId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateProductInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  brand?: InputMaybe<BrandInput>;
  brandId?: InputMaybe<Scalars['String']['input']>;
  brandNameId?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  ean?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  images?: InputMaybe<Array<InputMaybe<Scalars['File']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProductResult = {
  __typename?: 'UpdateProductResult';
  id: Scalars['ID']['output'];
};

export type UpdateReelInput = {
  file: Scalars['File']['input'];
  reelId: Scalars['ID']['input'];
};

export type UpsertProductInput = {
  brandId?: InputMaybe<Scalars['ID']['input']>;
  brandNameId?: InputMaybe<Scalars['ID']['input']>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  ean?: InputMaybe<Scalars['String']['input']>;
  existingImages?: InputMaybe<Array<InputMaybe<ExistingImageInput>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['File']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertUserDeliveryAddressInput = {
  additionalInformation?: InputMaybe<Scalars['String']['input']>;
  apartmentNumber?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  floor?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  street: Scalars['String']['input'];
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  zipNumber: Scalars['String']['input'];
};

export type UpsertWarehousePackInput = {
  item?: InputMaybe<UpsertWarehousePackProductInput>;
  locationId: Scalars['String']['input'];
  warehousePackId?: InputMaybe<Scalars['String']['input']>;
};

export type UpsertWarehousePackProductInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  ean?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<Scalars['Json']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpsertWarehousePackResult = {
  __typename?: 'UpsertWarehousePackResult';
  lastAddedItem?: Maybe<WarehouseProduct>;
  warehousePack?: Maybe<WarehousePack>;
};

export type User = {
  __typename?: 'User';
  businessId?: Maybe<Scalars['ID']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  notifications?: Maybe<NotificationConfig>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type UserAddress = {
  __typename?: 'UserAddress';
  address?: Maybe<Scalars['Json']['output']>;
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};

export type UserAddressCopy = {
  __typename?: 'UserAddressCopy';
  address?: Maybe<Scalars['Json']['output']>;
  createdAt: Scalars['String']['output'];
  id?: Maybe<Scalars['ID']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type UserAddressResponse = {
  __typename?: 'UserAddressResponse';
  data?: Maybe<UserAddress>;
  response: UserAddressResponseMetadata;
};

export type UserAddressResponseMetadata = {
  __typename?: 'UserAddressResponseMetadata';
  message?: Maybe<Scalars['String']['output']>;
  status: ResponseStatus;
};

export type UserBillAddressInput = {
  apartmentNumber?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  street: Scalars['String']['input'];
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  zipNumber: Scalars['String']['input'];
};

export type UserInvoiceAddressInput = {
  apartmentNumber?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  companyName: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email: Scalars['String']['input'];
  street: Scalars['String']['input'];
  streetNumber?: InputMaybe<Scalars['String']['input']>;
  taxNumber: Scalars['String']['input'];
  zipNumber: Scalars['String']['input'];
};

export type WarehousePack = {
  __typename?: 'WarehousePack';
  _count?: Maybe<WarehousePackNestedCount>;
  createdAt?: Maybe<Scalars['String']['output']>;
  done?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  items?: Maybe<Array<WarehouseProduct>>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type WarehousePackNestedCount = {
  __typename?: 'WarehousePackNestedCount';
  items?: Maybe<Scalars['Int']['output']>;
};

export type WarehouseProduct = {
  __typename?: 'WarehouseProduct';
  createdAt?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  ean?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Scalars['Json']['output']>;
  meta?: Maybe<Scalars['Json']['output']>;
  name: Scalars['String']['output'];
  price?: Maybe<Scalars['Int']['output']>;
  productId?: Maybe<Scalars['ID']['output']>;
  taxRate?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  warehousePackId: Scalars['ID']['output'];
  withoutEan?: Maybe<Scalars['Boolean']['output']>;
};

export type WarehouseProductData = {
  __typename?: 'WarehouseProductData';
  active: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  location: Location;
  product: Product;
};

export type Website = {
  __typename?: 'Website';
  type?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type WebsiteInput = {
  type: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type AdminProductsToVerifyCountQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminProductsToVerifyCountQuery = { __typename?: 'Query', adminProductsToVerifyCount: number };

export type LocationsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type LocationsCountQuery = { __typename?: 'Query', counts?: { __typename?: 'Counts', location?: { __typename?: 'CountsResult', count: number, diff: Array<{ __typename?: 'MonthCountResult', count: any, date?: any | null } | null> } | null } | null };

export type ProductRequestUpdateCountQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductRequestUpdateCountQuery = { __typename?: 'Query', counts?: { __typename?: 'Counts', productRequestUpdate?: { __typename?: 'CountsResult', count: number, diff: Array<{ __typename?: 'MonthCountResult', count: any, date?: any | null } | null> } | null } | null };

export type ProductsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsCountQuery = { __typename?: 'Query', counts?: { __typename?: 'Counts', product?: { __typename?: 'CountsResult', count: number, diff: Array<{ __typename?: 'MonthCountResult', count: any, date?: any | null } | null> } | null } | null };

export type AdminBrandQueryVariables = Exact<{
  brandId: Scalars['ID']['input'];
}>;


export type AdminBrandQuery = { __typename?: 'Query', adminBrand?: { __typename?: 'AdminBrand', id: string, name?: string | null, meta?: any | null, productCount?: number | null, products?: Array<{ __typename?: 'Product', id: string, name: string, images?: Array<{ __typename?: 'Image', url?: string | null } | null> | null } | null> | null, names?: Array<{ __typename?: 'BrandName', id: string, name?: string | null } | null> | null } | null };

export type AdminBrandNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminBrandNamesQuery = { __typename?: 'Query', adminBrandNames?: Array<{ __typename?: 'BrandName', id: string, name?: string | null, manufacturerId?: string | null } | null> | null };

export type AdminBrandsQueryVariables = Exact<{
  input?: InputMaybe<BrandsInput>;
}>;


export type AdminBrandsQuery = { __typename?: 'Query', adminBrands: { __typename?: 'PaginatedBrands', data?: Array<{ __typename?: 'Brand', id: string, name?: string | null, names?: Array<{ __typename?: 'BrandName', id: string, name?: string | null } | null> | null } | null> | null } };

export type AdminDeleteBrandMutationVariables = Exact<{
  brandId: Scalars['ID']['input'];
}>;


export type AdminDeleteBrandMutation = { __typename?: 'Mutation', adminDeleteBrand?: { __typename?: 'AdminBrand', id: string } | null };

export type AdminDeleteBrandNameMutationVariables = Exact<{
  brandNameId: Scalars['ID']['input'];
}>;


export type AdminDeleteBrandNameMutation = { __typename?: 'Mutation', adminDeleteBrandName?: { __typename?: 'BrandName', id: string, name?: string | null } | null };

export type AdminUpsertBrandMutationVariables = Exact<{
  input: AdminUpsertBrandInput;
}>;


export type AdminUpsertBrandMutation = { __typename?: 'Mutation', adminUpsertBrand?: { __typename?: 'AdminBrand', id: string, name?: string | null, meta?: any | null } | null };

export type AdminUpsertBrandNameMutationVariables = Exact<{
  input: AdminUpsertBrandNameInput;
}>;


export type AdminUpsertBrandNameMutation = { __typename?: 'Mutation', adminUpsertBrandName?: { __typename?: 'BrandName', id: string, name?: string | null } | null };

export type BrandQueryVariables = Exact<{
  brandId: Scalars['ID']['input'];
}>;


export type BrandQuery = { __typename?: 'Query', brand?: { __typename?: 'Brand', id: string, name?: string | null, meta?: any | null } | null };

export type CreateBrandMutationVariables = Exact<{
  input: CreateBrandInput;
}>;


export type CreateBrandMutation = { __typename?: 'Mutation', createBrand: { __typename?: 'Brand', id: string } };

export type SearchBrandOnlineQueryVariables = Exact<{
  input: SearchBrandOnlineInput;
}>;


export type SearchBrandOnlineQuery = { __typename?: 'Query', searchBrandOnline?: any | null };

export type AdminBusinessQueryVariables = Exact<{
  businessId: Scalars['ID']['input'];
}>;


export type AdminBusinessQuery = { __typename?: 'Query', adminBusiness?: { __typename?: 'Business', id?: string | null, name?: string | null, meta?: any | null } | null };

export type AdminBusinessesQueryVariables = Exact<{
  input?: InputMaybe<PaginatedInput>;
}>;


export type AdminBusinessesQuery = { __typename?: 'Query', adminBusinesses: { __typename?: 'PaginatedBusiness', cursor?: string | null, data?: Array<{ __typename?: 'Business', id?: string | null, name?: string | null } | null> | null } };

export type AdminContextualDiscoveriesQueryVariables = Exact<{
  input?: InputMaybe<AdminContextualDiscoveryInput>;
}>;


export type AdminContextualDiscoveriesQuery = { __typename?: 'Query', adminContextualDiscoveries?: { __typename?: 'PaginatedAdminContextualDiscovery', cursor?: string | null, data?: Array<{ __typename?: 'AdminContextualDiscovery', id?: string | null, name?: string | null, active?: boolean | null } | null> | null } | null };

export type AdminContextualDiscoveryQueryVariables = Exact<{
  contextualDiscoveryId: Scalars['ID']['input'];
}>;


export type AdminContextualDiscoveryQuery = { __typename?: 'Query', adminContextualDiscovery?: { __typename?: 'AdminContextualDiscovery', id?: string | null, name?: string | null, active?: boolean | null, categories?: Array<{ __typename?: 'AdminContextualDiscoveryCategory', id: string, category?: { __typename?: 'LocationCategory', id: string, name: string } | null, locations?: Array<{ __typename?: 'Location', id: string, name?: string | null } | null> | null } | null> | null } | null };

export type AdminDisconnectContextualDiscoveryCategoryMutationVariables = Exact<{
  contextualDiscoveryCategoryId: Scalars['ID']['input'];
}>;


export type AdminDisconnectContextualDiscoveryCategoryMutation = { __typename?: 'Mutation', adminDisconnectContextualDiscoveryCategory?: { __typename?: 'AdminDisconnectContextualDiscoveryCategoryResult', id: string } | null };

export type AdminDisconnectContextualDiscoveryCategoryLocationMutationVariables = Exact<{
  input: AdminUpsertContextualDiscoveryCategoryLocationInput;
}>;


export type AdminDisconnectContextualDiscoveryCategoryLocationMutation = { __typename?: 'Mutation', adminDisconnectContextualDiscoveryCategoryLocation?: { __typename?: 'AdminContextualDiscoveryCategoryLocation', locationId: string } | null };

export type AdminToggleContextualDiscoveryActiveMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AdminToggleContextualDiscoveryActiveMutation = { __typename?: 'Mutation', adminToggleContextualDiscoveryActive?: boolean | null };

export type AdminUpsertContextualDiscoveryCategoryMutationVariables = Exact<{
  input: AdminUpsertContextualDiscoveryCategoryInput;
}>;


export type AdminUpsertContextualDiscoveryCategoryMutation = { __typename?: 'Mutation', adminUpsertContextualDiscoveryCategory?: { __typename?: 'AdminContextualDiscoveryCategory', id: string } | null };

export type AdminUpsertContextualDiscoveryCategoryLocationMutationVariables = Exact<{
  input: AdminUpsertContextualDiscoveryCategoryLocationInput;
}>;


export type AdminUpsertContextualDiscoveryCategoryLocationMutation = { __typename?: 'Mutation', adminUpsertContextualDiscoveryCategoryLocation?: { __typename?: 'AdminContextualDiscoveryCategoryLocation', locationId: string } | null };

export type AdminUpsertLocationCategoryMutationVariables = Exact<{
  input: AdminUpsertLocationCategoryInput;
}>;


export type AdminUpsertLocationCategoryMutation = { __typename?: 'Mutation', adminUpsertLocationCategory: string };

export type MerchantLocationCategoryQueryVariables = Exact<{ [key: string]: never; }>;


export type MerchantLocationCategoryQuery = { __typename?: 'Query', merchantLocationCategory?: Array<{ __typename?: 'LocationCategory', id: string, name: string } | null> | null };

export type EmailsQueryVariables = Exact<{ [key: string]: never; }>;


export type EmailsQuery = { __typename?: 'Query', adminListEmails?: Array<{ __typename?: 'Email', id: string, template: string, subject: string, name: string } | null> | null };

export type AdminBusinessOwnersQueryVariables = Exact<{
  input: AdminBusinessOwnersInput;
}>;


export type AdminBusinessOwnersQuery = { __typename?: 'Query', adminBusinessOwners?: Array<{ __typename?: 'AdminBusinessOwner', businessId: string, ownerId?: string | null, avatar?: string | null, email?: string | null } | null> | null };

export type AdminLocationQueryVariables = Exact<{
  locationId: Scalars['ID']['input'];
}>;


export type AdminLocationQuery = { __typename?: 'Query', adminLocation?: { __typename?: 'AdminLocation', location: { __typename?: 'Location', id: string, name?: string | null, flags?: any | null, address?: string | null, latitude?: number | null, longitude?: number | null, images?: Array<{ __typename?: 'Image', id?: string | null, url?: string | null, isPrimary?: boolean | null, size?: string | null } | null> | null }, business?: { __typename?: 'Business', id?: string | null, name?: string | null, taxId?: string | null, regon?: string | null, owner?: { __typename?: 'User', id: string, email?: string | null } | null } | null } | null };

export type AdminLocationsQueryVariables = Exact<{
  input?: InputMaybe<PaginatedInput>;
}>;


export type AdminLocationsQuery = { __typename?: 'Query', adminLocations: { __typename?: 'PaginatedLocation', cursor?: string | null, data?: Array<{ __typename?: 'Location', id: string, name?: string | null } | null> | null } };

export type AdminUpsertLocationOwnerMutationVariables = Exact<{
  input: AdminUpsertLocationOwnerInput;
}>;


export type AdminUpsertLocationOwnerMutation = { __typename?: 'Mutation', adminUpsertLocationOwner?: { __typename?: 'AdminUpsertLocationOwnerResult', ok: boolean } | null };

export type AdminCreateMerchantMutationVariables = Exact<{
  input: AdminCreateMerchantInput;
}>;


export type AdminCreateMerchantMutation = { __typename?: 'Mutation', adminCreateMerchant: { __typename?: 'Merchant', name?: string | null, email: string } };

export type CategoryQueryVariables = Exact<{
  categoryId: Scalars['ID']['input'];
}>;


export type CategoryQuery = { __typename?: 'Query', category: { __typename?: 'Category', id?: string | null, name?: string | null, parentId?: string | null, taxId?: string | null, meta?: any | null, tree?: Array<string | null> | null, counts?: { __typename?: 'CategoryNestedCount', products?: number | null } | null, parent?: { __typename?: 'Category', id?: string | null, name?: string | null } | null, children?: Array<{ __typename?: 'Category', id?: string | null, name?: string | null, children?: Array<{ __typename?: 'Category', id?: string | null, name?: string | null } | null> | null } | null> | null } };

export type CreateCategoryMutationVariables = Exact<{
  input: CategoryInput;
}>;


export type CreateCategoryMutation = { __typename?: 'Mutation', createCategory?: { __typename?: 'Category', id?: string | null, name?: string | null } | null };

export type DeleteCategoryMutationVariables = Exact<{
  categoryId: Scalars['ID']['input'];
}>;


export type DeleteCategoryMutation = { __typename?: 'Mutation', deleteCategory?: { __typename?: 'Category', id?: string | null } | null };

export type ProductCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductCategoriesQuery = { __typename?: 'Query', productCategories: Array<{ __typename?: 'Category', id?: string | null, name?: string | null, tree?: Array<string | null> | null, updatedAt?: string | null }> };

export type UpdateCategoryMutationVariables = Exact<{
  category: UpdateCategoryInput;
}>;


export type UpdateCategoryMutation = { __typename?: 'Mutation', updateCategory?: { __typename?: 'Category', id?: string | null } | null };

export type AdminAcceptProductSuggestionMutationVariables = Exact<{
  input: AdminAcceptProductSuggestionInput;
}>;


export type AdminAcceptProductSuggestionMutation = { __typename?: 'Mutation', adminAcceptProductSuggestion?: { __typename?: 'AdminAcceptProductSuggestionResult', ok?: boolean | null } | null };

export type AdminProductToVerifyQueryVariables = Exact<{
  productSuggestionId: Scalars['ID']['input'];
}>;


export type AdminProductToVerifyQuery = { __typename?: 'Query', adminProductToVerify?: { __typename?: 'AdminProductToVerify', id?: string | null, data?: any | null, updatedAt?: string | null, createdAt?: string | null, status?: string | null, product?: { __typename?: 'Product', id: string, name: string, ean?: string | null } | null, requester?: { __typename?: 'User', email?: string | null } | null, suggestions?: Array<{ __typename?: 'ProductSuggestion', id?: string | null, reason?: string | null, status?: string | null, rejectedType?: string | null, rejectedMessage?: string | null, data?: any | null, createdAt?: string | null } | null> | null } | null };

export type AdminProductsToVerifyQueryVariables = Exact<{
  input?: InputMaybe<AdminProductsToVerifyInput>;
}>;


export type AdminProductsToVerifyQuery = { __typename?: 'Query', adminProductsToVerify?: { __typename?: 'AdminPaginatedProductsToVerify', cursor?: string | null, data?: Array<{ __typename?: 'AdminProductToVerify', id?: string | null, updatedAt?: string | null, createdAt?: string | null, status?: string | null, product?: { __typename?: 'Product', id: string, name: string, ean?: string | null } | null, requester?: { __typename?: 'User', email?: string | null } | null, suggestions?: Array<{ __typename?: 'ProductSuggestion', id?: string | null, reason?: string | null, status?: string | null, rejectedType?: string | null, rejectedMessage?: string | null, data?: any | null, createdAt?: string | null } | null> | null } | null> | null } | null };

export type AdminRejectProductSuggestionMutationVariables = Exact<{
  productRequestUpdateId: Scalars['ID']['input'];
}>;


export type AdminRejectProductSuggestionMutation = { __typename?: 'Mutation', adminRejectProductSuggestion?: { __typename?: 'AdminAcceptProductSuggestionResult', ok?: boolean | null } | null };

export type AdminDeleteProductMutationVariables = Exact<{
  input: AdminDeleteProductInput;
}>;


export type AdminDeleteProductMutation = { __typename?: 'Mutation', adminDeleteProduct?: { __typename?: 'Product', id: string } | null };

export type AdminFindProductDuplicateQueryVariables = Exact<{
  input: AdminFindProductDuplicateInput;
}>;


export type AdminFindProductDuplicateQuery = { __typename?: 'Query', adminFindProductDuplicate?: Array<{ __typename?: 'Product', id: string, name: string, ean?: string | null } | null> | null };

export type AdminProductsQueryVariables = Exact<{
  input?: InputMaybe<ProductsInput2>;
}>;


export type AdminProductsQuery = { __typename?: 'Query', adminProducts: { __typename?: 'PaginatedProducts', cursor?: string | null, data?: Array<{ __typename?: 'Product', id: string, name: string, ean?: string | null, createdAt?: string | null, updatedAt?: string | null, brandId?: string | null, brandNameId?: string | null, categoryId?: string | null, active?: boolean | null, images?: Array<{ __typename?: 'Image', url?: string | null } | null> | null } | null> | null } };

export type DeleteProductImageMutationVariables = Exact<{
  input: DeleteProductImageInput;
}>;


export type DeleteProductImageMutation = { __typename?: 'Mutation', deleteProductImage: { __typename?: 'DeleteProductImageResult', deletedImageId?: string | null } };

export type ProductQueryVariables = Exact<{
  filters?: InputMaybe<Array<InputMaybe<ProductFilter>> | InputMaybe<ProductFilter>>;
}>;


export type ProductQuery = { __typename?: 'Query', findProduct?: { __typename?: 'Product', id: string, name: string, ean?: string | null, description?: string | null, category?: { __typename?: 'Category', id?: string | null, name?: string | null } | null, brand?: { __typename?: 'Brand', id: string, name?: string | null } | null, images?: Array<{ __typename?: 'Image', id?: string | null, url?: string | null, isPrimary?: boolean | null } | null> | null, offers?: Array<{ __typename?: 'Offer', id: string, active?: boolean | null, location?: { __typename?: 'Location', id: string, name?: string | null } | null } | null> | null } | null };

export type ProductsQueryVariables = Exact<{
  input?: InputMaybe<ProductsInput2>;
}>;


export type ProductsQuery = { __typename?: 'Query', products: { __typename?: 'PaginatedProducts', cursor?: string | null, data?: Array<{ __typename?: 'Product', id: string, name: string, ean?: string | null, createdAt?: string | null, updatedAt?: string | null, brandId?: string | null, brandNameId?: string | null, categoryId?: string | null, active?: boolean | null, images?: Array<{ __typename?: 'Image', url?: string | null } | null> | null } | null> | null } };

export type TryToCreateProductFromOnlineMutationVariables = Exact<{
  gtin: Scalars['String']['input'];
}>;


export type TryToCreateProductFromOnlineMutation = { __typename?: 'Mutation', tryToCreateProductFromOnline?: { __typename?: 'Product', id: string, name: string, ean?: string | null, brandId?: string | null, categoryId?: string | null, description?: string | null, existing?: boolean | null, images?: Array<{ __typename?: 'Image', id?: string | null, url?: string | null } | null> | null } | null };

export type TryToFindProductOnlineQueryVariables = Exact<{
  input?: InputMaybe<TryToFindProductOnlineInput>;
}>;


export type TryToFindProductOnlineQuery = { __typename?: 'Query', tryToFindProductOnline?: any | null };

export type UpdateProductMutationVariables = Exact<{
  input: UpdateProductInput;
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', updateProduct: { __typename?: 'UpdateProductResult', id: string } };

export type UpsertProductMutationVariables = Exact<{
  input: UpsertProductInput;
}>;


export type UpsertProductMutation = { __typename?: 'Mutation', upsertProduct: { __typename?: 'UpdateProductResult', id: string } };

export type AdminAddTaxMutationVariables = Exact<{
  input: AddTaxInput;
}>;


export type AdminAddTaxMutation = { __typename?: 'Mutation', adminAddTax: { __typename?: 'Tax', id: string, rate: number, description?: string | null } };

export type AdminTaxesQueryVariables = Exact<{
  input?: InputMaybe<PaginatedInput>;
}>;


export type AdminTaxesQuery = { __typename?: 'Query', adminTaxes: { __typename?: 'PaginatedTaxes', cursor?: string | null, data?: Array<{ __typename?: 'Tax', id: string, rate: number, description?: string | null, createdAt: string, updatedAt: string } | null> | null } };

export type AdminUserQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type AdminUserQuery = { __typename?: 'Query', adminUser?: { __typename?: 'AdminUser', id: string, avatar?: string | null, phoneNumber?: string | null, email?: string | null, stripeCustomerId?: string | null, role?: string | null, businessId?: string | null, createdAt: string, updatedAt: string } | null };

export type AdminUsersQueryVariables = Exact<{
  input: PaginatedInput;
}>;


export type AdminUsersQuery = { __typename?: 'Query', adminUsers?: { __typename?: 'PaginatedUsers', cursor?: string | null, data?: Array<{ __typename?: 'AdminUser', id: string, avatar?: string | null, phoneNumber?: string | null, email?: string | null, stripeCustomerId?: string | null, role?: string | null, businessId?: string | null, createdAt: string, updatedAt: string } | null> | null } | null };


export const AdminProductsToVerifyCountDocument = `
    query AdminProductsToVerifyCount {
  adminProductsToVerifyCount
}
    `;
export const useAdminProductsToVerifyCountQuery = <
      TData = AdminProductsToVerifyCountQuery,
      TError = unknown
    >(
      variables?: AdminProductsToVerifyCountQueryVariables,
      options?: UseQueryOptions<AdminProductsToVerifyCountQuery, TError, TData>
    ) =>
    useQuery<AdminProductsToVerifyCountQuery, TError, TData>(
      variables === undefined ? ['AdminProductsToVerifyCount'] : ['AdminProductsToVerifyCount', variables],
      useFetchData<AdminProductsToVerifyCountQuery, AdminProductsToVerifyCountQueryVariables>(AdminProductsToVerifyCountDocument).bind(null, variables),
      options
    );
export const useInfiniteAdminProductsToVerifyCountQuery = <
      TData = AdminProductsToVerifyCountQuery,
      TError = unknown
    >(
      variables?: AdminProductsToVerifyCountQueryVariables,
      options?: UseInfiniteQueryOptions<AdminProductsToVerifyCountQuery, TError, TData>
    ) =>{
    const query = useFetchData<AdminProductsToVerifyCountQuery, AdminProductsToVerifyCountQueryVariables>(AdminProductsToVerifyCountDocument)
    return useInfiniteQuery<AdminProductsToVerifyCountQuery, TError, TData>(
      variables === undefined ? ['AdminProductsToVerifyCount.infinite'] : ['AdminProductsToVerifyCount.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const LocationsCountDocument = `
    query LocationsCount {
  counts {
    location {
      count
      diff {
        count
        date
      }
    }
  }
}
    `;
export const useLocationsCountQuery = <
      TData = LocationsCountQuery,
      TError = unknown
    >(
      variables?: LocationsCountQueryVariables,
      options?: UseQueryOptions<LocationsCountQuery, TError, TData>
    ) =>
    useQuery<LocationsCountQuery, TError, TData>(
      variables === undefined ? ['LocationsCount'] : ['LocationsCount', variables],
      useFetchData<LocationsCountQuery, LocationsCountQueryVariables>(LocationsCountDocument).bind(null, variables),
      options
    );
export const useInfiniteLocationsCountQuery = <
      TData = LocationsCountQuery,
      TError = unknown
    >(
      variables?: LocationsCountQueryVariables,
      options?: UseInfiniteQueryOptions<LocationsCountQuery, TError, TData>
    ) =>{
    const query = useFetchData<LocationsCountQuery, LocationsCountQueryVariables>(LocationsCountDocument)
    return useInfiniteQuery<LocationsCountQuery, TError, TData>(
      variables === undefined ? ['LocationsCount.infinite'] : ['LocationsCount.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const ProductRequestUpdateCountDocument = `
    query ProductRequestUpdateCount {
  counts {
    productRequestUpdate {
      count
      diff {
        count
        date
      }
    }
  }
}
    `;
export const useProductRequestUpdateCountQuery = <
      TData = ProductRequestUpdateCountQuery,
      TError = unknown
    >(
      variables?: ProductRequestUpdateCountQueryVariables,
      options?: UseQueryOptions<ProductRequestUpdateCountQuery, TError, TData>
    ) =>
    useQuery<ProductRequestUpdateCountQuery, TError, TData>(
      variables === undefined ? ['ProductRequestUpdateCount'] : ['ProductRequestUpdateCount', variables],
      useFetchData<ProductRequestUpdateCountQuery, ProductRequestUpdateCountQueryVariables>(ProductRequestUpdateCountDocument).bind(null, variables),
      options
    );
export const useInfiniteProductRequestUpdateCountQuery = <
      TData = ProductRequestUpdateCountQuery,
      TError = unknown
    >(
      variables?: ProductRequestUpdateCountQueryVariables,
      options?: UseInfiniteQueryOptions<ProductRequestUpdateCountQuery, TError, TData>
    ) =>{
    const query = useFetchData<ProductRequestUpdateCountQuery, ProductRequestUpdateCountQueryVariables>(ProductRequestUpdateCountDocument)
    return useInfiniteQuery<ProductRequestUpdateCountQuery, TError, TData>(
      variables === undefined ? ['ProductRequestUpdateCount.infinite'] : ['ProductRequestUpdateCount.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const ProductsCountDocument = `
    query ProductsCount {
  counts {
    product {
      count
      diff {
        count
        date
      }
    }
  }
}
    `;
export const useProductsCountQuery = <
      TData = ProductsCountQuery,
      TError = unknown
    >(
      variables?: ProductsCountQueryVariables,
      options?: UseQueryOptions<ProductsCountQuery, TError, TData>
    ) =>
    useQuery<ProductsCountQuery, TError, TData>(
      variables === undefined ? ['ProductsCount'] : ['ProductsCount', variables],
      useFetchData<ProductsCountQuery, ProductsCountQueryVariables>(ProductsCountDocument).bind(null, variables),
      options
    );
export const useInfiniteProductsCountQuery = <
      TData = ProductsCountQuery,
      TError = unknown
    >(
      variables?: ProductsCountQueryVariables,
      options?: UseInfiniteQueryOptions<ProductsCountQuery, TError, TData>
    ) =>{
    const query = useFetchData<ProductsCountQuery, ProductsCountQueryVariables>(ProductsCountDocument)
    return useInfiniteQuery<ProductsCountQuery, TError, TData>(
      variables === undefined ? ['ProductsCount.infinite'] : ['ProductsCount.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const AdminBrandDocument = `
    query AdminBrand($brandId: ID!) {
  adminBrand(brandId: $brandId) {
    id
    name
    meta
    productCount
    products {
      id
      name
      images {
        url
      }
    }
    names {
      id
      name
    }
  }
}
    `;
export const useAdminBrandQuery = <
      TData = AdminBrandQuery,
      TError = unknown
    >(
      variables: AdminBrandQueryVariables,
      options?: UseQueryOptions<AdminBrandQuery, TError, TData>
    ) =>
    useQuery<AdminBrandQuery, TError, TData>(
      ['AdminBrand', variables],
      useFetchData<AdminBrandQuery, AdminBrandQueryVariables>(AdminBrandDocument).bind(null, variables),
      options
    );
export const useInfiniteAdminBrandQuery = <
      TData = AdminBrandQuery,
      TError = unknown
    >(
      variables: AdminBrandQueryVariables,
      options?: UseInfiniteQueryOptions<AdminBrandQuery, TError, TData>
    ) =>{
    const query = useFetchData<AdminBrandQuery, AdminBrandQueryVariables>(AdminBrandDocument)
    return useInfiniteQuery<AdminBrandQuery, TError, TData>(
      ['AdminBrand.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const AdminBrandNamesDocument = `
    query AdminBrandNames {
  adminBrandNames {
    id
    name
    manufacturerId
  }
}
    `;
export const useAdminBrandNamesQuery = <
      TData = AdminBrandNamesQuery,
      TError = unknown
    >(
      variables?: AdminBrandNamesQueryVariables,
      options?: UseQueryOptions<AdminBrandNamesQuery, TError, TData>
    ) =>
    useQuery<AdminBrandNamesQuery, TError, TData>(
      variables === undefined ? ['AdminBrandNames'] : ['AdminBrandNames', variables],
      useFetchData<AdminBrandNamesQuery, AdminBrandNamesQueryVariables>(AdminBrandNamesDocument).bind(null, variables),
      options
    );
export const useInfiniteAdminBrandNamesQuery = <
      TData = AdminBrandNamesQuery,
      TError = unknown
    >(
      variables?: AdminBrandNamesQueryVariables,
      options?: UseInfiniteQueryOptions<AdminBrandNamesQuery, TError, TData>
    ) =>{
    const query = useFetchData<AdminBrandNamesQuery, AdminBrandNamesQueryVariables>(AdminBrandNamesDocument)
    return useInfiniteQuery<AdminBrandNamesQuery, TError, TData>(
      variables === undefined ? ['AdminBrandNames.infinite'] : ['AdminBrandNames.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const AdminBrandsDocument = `
    query AdminBrands($input: BrandsInput) {
  adminBrands(input: $input) {
    data {
      id
      name
      names {
        id
        name
      }
    }
  }
}
    `;
export const useAdminBrandsQuery = <
      TData = AdminBrandsQuery,
      TError = unknown
    >(
      variables?: AdminBrandsQueryVariables,
      options?: UseQueryOptions<AdminBrandsQuery, TError, TData>
    ) =>
    useQuery<AdminBrandsQuery, TError, TData>(
      variables === undefined ? ['AdminBrands'] : ['AdminBrands', variables],
      useFetchData<AdminBrandsQuery, AdminBrandsQueryVariables>(AdminBrandsDocument).bind(null, variables),
      options
    );
export const useInfiniteAdminBrandsQuery = <
      TData = AdminBrandsQuery,
      TError = unknown
    >(
      variables?: AdminBrandsQueryVariables,
      options?: UseInfiniteQueryOptions<AdminBrandsQuery, TError, TData>
    ) =>{
    const query = useFetchData<AdminBrandsQuery, AdminBrandsQueryVariables>(AdminBrandsDocument)
    return useInfiniteQuery<AdminBrandsQuery, TError, TData>(
      variables === undefined ? ['AdminBrands.infinite'] : ['AdminBrands.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const AdminDeleteBrandDocument = `
    mutation AdminDeleteBrand($brandId: ID!) {
  adminDeleteBrand(brandId: $brandId) {
    id
  }
}
    `;
export const useAdminDeleteBrandMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AdminDeleteBrandMutation, TError, AdminDeleteBrandMutationVariables, TContext>) =>
    useMutation<AdminDeleteBrandMutation, TError, AdminDeleteBrandMutationVariables, TContext>(
      ['AdminDeleteBrand'],
      useFetchData<AdminDeleteBrandMutation, AdminDeleteBrandMutationVariables>(AdminDeleteBrandDocument),
      options
    );
export const AdminDeleteBrandNameDocument = `
    mutation AdminDeleteBrandName($brandNameId: ID!) {
  adminDeleteBrandName(brandNameId: $brandNameId) {
    id
    name
  }
}
    `;
export const useAdminDeleteBrandNameMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AdminDeleteBrandNameMutation, TError, AdminDeleteBrandNameMutationVariables, TContext>) =>
    useMutation<AdminDeleteBrandNameMutation, TError, AdminDeleteBrandNameMutationVariables, TContext>(
      ['AdminDeleteBrandName'],
      useFetchData<AdminDeleteBrandNameMutation, AdminDeleteBrandNameMutationVariables>(AdminDeleteBrandNameDocument),
      options
    );
export const AdminUpsertBrandDocument = `
    mutation AdminUpsertBrand($input: AdminUpsertBrandInput!) {
  adminUpsertBrand(input: $input) {
    id
    name
    meta
  }
}
    `;
export const useAdminUpsertBrandMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AdminUpsertBrandMutation, TError, AdminUpsertBrandMutationVariables, TContext>) =>
    useMutation<AdminUpsertBrandMutation, TError, AdminUpsertBrandMutationVariables, TContext>(
      ['AdminUpsertBrand'],
      useFetchData<AdminUpsertBrandMutation, AdminUpsertBrandMutationVariables>(AdminUpsertBrandDocument),
      options
    );
export const AdminUpsertBrandNameDocument = `
    mutation AdminUpsertBrandName($input: AdminUpsertBrandNameInput!) {
  adminUpsertBrandName(input: $input) {
    id
    name
  }
}
    `;
export const useAdminUpsertBrandNameMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AdminUpsertBrandNameMutation, TError, AdminUpsertBrandNameMutationVariables, TContext>) =>
    useMutation<AdminUpsertBrandNameMutation, TError, AdminUpsertBrandNameMutationVariables, TContext>(
      ['AdminUpsertBrandName'],
      useFetchData<AdminUpsertBrandNameMutation, AdminUpsertBrandNameMutationVariables>(AdminUpsertBrandNameDocument),
      options
    );
export const BrandDocument = `
    query Brand($brandId: ID!) {
  brand(brandId: $brandId) {
    id
    name
    meta
  }
}
    `;
export const useBrandQuery = <
      TData = BrandQuery,
      TError = unknown
    >(
      variables: BrandQueryVariables,
      options?: UseQueryOptions<BrandQuery, TError, TData>
    ) =>
    useQuery<BrandQuery, TError, TData>(
      ['Brand', variables],
      useFetchData<BrandQuery, BrandQueryVariables>(BrandDocument).bind(null, variables),
      options
    );
export const useInfiniteBrandQuery = <
      TData = BrandQuery,
      TError = unknown
    >(
      variables: BrandQueryVariables,
      options?: UseInfiniteQueryOptions<BrandQuery, TError, TData>
    ) =>{
    const query = useFetchData<BrandQuery, BrandQueryVariables>(BrandDocument)
    return useInfiniteQuery<BrandQuery, TError, TData>(
      ['Brand.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const CreateBrandDocument = `
    mutation CreateBrand($input: CreateBrandInput!) {
  createBrand(input: $input) {
    id
  }
}
    `;
export const useCreateBrandMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateBrandMutation, TError, CreateBrandMutationVariables, TContext>) =>
    useMutation<CreateBrandMutation, TError, CreateBrandMutationVariables, TContext>(
      ['CreateBrand'],
      useFetchData<CreateBrandMutation, CreateBrandMutationVariables>(CreateBrandDocument),
      options
    );
export const SearchBrandOnlineDocument = `
    query SearchBrandOnline($input: SearchBrandOnlineInput!) {
  searchBrandOnline(input: $input)
}
    `;
export const useSearchBrandOnlineQuery = <
      TData = SearchBrandOnlineQuery,
      TError = unknown
    >(
      variables: SearchBrandOnlineQueryVariables,
      options?: UseQueryOptions<SearchBrandOnlineQuery, TError, TData>
    ) =>
    useQuery<SearchBrandOnlineQuery, TError, TData>(
      ['SearchBrandOnline', variables],
      useFetchData<SearchBrandOnlineQuery, SearchBrandOnlineQueryVariables>(SearchBrandOnlineDocument).bind(null, variables),
      options
    );
export const useInfiniteSearchBrandOnlineQuery = <
      TData = SearchBrandOnlineQuery,
      TError = unknown
    >(
      variables: SearchBrandOnlineQueryVariables,
      options?: UseInfiniteQueryOptions<SearchBrandOnlineQuery, TError, TData>
    ) =>{
    const query = useFetchData<SearchBrandOnlineQuery, SearchBrandOnlineQueryVariables>(SearchBrandOnlineDocument)
    return useInfiniteQuery<SearchBrandOnlineQuery, TError, TData>(
      ['SearchBrandOnline.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const AdminBusinessDocument = `
    query AdminBusiness($businessId: ID!) {
  adminBusiness(businessId: $businessId) {
    id
    name
    meta
  }
}
    `;
export const useAdminBusinessQuery = <
      TData = AdminBusinessQuery,
      TError = unknown
    >(
      variables: AdminBusinessQueryVariables,
      options?: UseQueryOptions<AdminBusinessQuery, TError, TData>
    ) =>
    useQuery<AdminBusinessQuery, TError, TData>(
      ['AdminBusiness', variables],
      useFetchData<AdminBusinessQuery, AdminBusinessQueryVariables>(AdminBusinessDocument).bind(null, variables),
      options
    );
export const useInfiniteAdminBusinessQuery = <
      TData = AdminBusinessQuery,
      TError = unknown
    >(
      variables: AdminBusinessQueryVariables,
      options?: UseInfiniteQueryOptions<AdminBusinessQuery, TError, TData>
    ) =>{
    const query = useFetchData<AdminBusinessQuery, AdminBusinessQueryVariables>(AdminBusinessDocument)
    return useInfiniteQuery<AdminBusinessQuery, TError, TData>(
      ['AdminBusiness.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const AdminBusinessesDocument = `
    query AdminBusinesses($input: PaginatedInput) {
  adminBusinesses(input: $input) {
    data {
      id
      name
    }
    cursor
  }
}
    `;
export const useAdminBusinessesQuery = <
      TData = AdminBusinessesQuery,
      TError = unknown
    >(
      variables?: AdminBusinessesQueryVariables,
      options?: UseQueryOptions<AdminBusinessesQuery, TError, TData>
    ) =>
    useQuery<AdminBusinessesQuery, TError, TData>(
      variables === undefined ? ['AdminBusinesses'] : ['AdminBusinesses', variables],
      useFetchData<AdminBusinessesQuery, AdminBusinessesQueryVariables>(AdminBusinessesDocument).bind(null, variables),
      options
    );
export const useInfiniteAdminBusinessesQuery = <
      TData = AdminBusinessesQuery,
      TError = unknown
    >(
      variables?: AdminBusinessesQueryVariables,
      options?: UseInfiniteQueryOptions<AdminBusinessesQuery, TError, TData>
    ) =>{
    const query = useFetchData<AdminBusinessesQuery, AdminBusinessesQueryVariables>(AdminBusinessesDocument)
    return useInfiniteQuery<AdminBusinessesQuery, TError, TData>(
      variables === undefined ? ['AdminBusinesses.infinite'] : ['AdminBusinesses.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const AdminContextualDiscoveriesDocument = `
    query AdminContextualDiscoveries($input: AdminContextualDiscoveryInput) {
  adminContextualDiscoveries(input: $input) {
    data {
      id
      name
      active
    }
    cursor
  }
}
    `;
export const useAdminContextualDiscoveriesQuery = <
      TData = AdminContextualDiscoveriesQuery,
      TError = unknown
    >(
      variables?: AdminContextualDiscoveriesQueryVariables,
      options?: UseQueryOptions<AdminContextualDiscoveriesQuery, TError, TData>
    ) =>
    useQuery<AdminContextualDiscoveriesQuery, TError, TData>(
      variables === undefined ? ['AdminContextualDiscoveries'] : ['AdminContextualDiscoveries', variables],
      useFetchData<AdminContextualDiscoveriesQuery, AdminContextualDiscoveriesQueryVariables>(AdminContextualDiscoveriesDocument).bind(null, variables),
      options
    );
export const useInfiniteAdminContextualDiscoveriesQuery = <
      TData = AdminContextualDiscoveriesQuery,
      TError = unknown
    >(
      variables?: AdminContextualDiscoveriesQueryVariables,
      options?: UseInfiniteQueryOptions<AdminContextualDiscoveriesQuery, TError, TData>
    ) =>{
    const query = useFetchData<AdminContextualDiscoveriesQuery, AdminContextualDiscoveriesQueryVariables>(AdminContextualDiscoveriesDocument)
    return useInfiniteQuery<AdminContextualDiscoveriesQuery, TError, TData>(
      variables === undefined ? ['AdminContextualDiscoveries.infinite'] : ['AdminContextualDiscoveries.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const AdminContextualDiscoveryDocument = `
    query AdminContextualDiscovery($contextualDiscoveryId: ID!) {
  adminContextualDiscovery(contextualDiscoveryId: $contextualDiscoveryId) {
    id
    name
    active
    categories {
      id
      category {
        id
        name
      }
      locations {
        id
        name
      }
    }
  }
}
    `;
export const useAdminContextualDiscoveryQuery = <
      TData = AdminContextualDiscoveryQuery,
      TError = unknown
    >(
      variables: AdminContextualDiscoveryQueryVariables,
      options?: UseQueryOptions<AdminContextualDiscoveryQuery, TError, TData>
    ) =>
    useQuery<AdminContextualDiscoveryQuery, TError, TData>(
      ['AdminContextualDiscovery', variables],
      useFetchData<AdminContextualDiscoveryQuery, AdminContextualDiscoveryQueryVariables>(AdminContextualDiscoveryDocument).bind(null, variables),
      options
    );
export const useInfiniteAdminContextualDiscoveryQuery = <
      TData = AdminContextualDiscoveryQuery,
      TError = unknown
    >(
      variables: AdminContextualDiscoveryQueryVariables,
      options?: UseInfiniteQueryOptions<AdminContextualDiscoveryQuery, TError, TData>
    ) =>{
    const query = useFetchData<AdminContextualDiscoveryQuery, AdminContextualDiscoveryQueryVariables>(AdminContextualDiscoveryDocument)
    return useInfiniteQuery<AdminContextualDiscoveryQuery, TError, TData>(
      ['AdminContextualDiscovery.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const AdminDisconnectContextualDiscoveryCategoryDocument = `
    mutation AdminDisconnectContextualDiscoveryCategory($contextualDiscoveryCategoryId: ID!) {
  adminDisconnectContextualDiscoveryCategory(
    contextualDiscoveryCategoryId: $contextualDiscoveryCategoryId
  ) {
    id
  }
}
    `;
export const useAdminDisconnectContextualDiscoveryCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AdminDisconnectContextualDiscoveryCategoryMutation, TError, AdminDisconnectContextualDiscoveryCategoryMutationVariables, TContext>) =>
    useMutation<AdminDisconnectContextualDiscoveryCategoryMutation, TError, AdminDisconnectContextualDiscoveryCategoryMutationVariables, TContext>(
      ['AdminDisconnectContextualDiscoveryCategory'],
      useFetchData<AdminDisconnectContextualDiscoveryCategoryMutation, AdminDisconnectContextualDiscoveryCategoryMutationVariables>(AdminDisconnectContextualDiscoveryCategoryDocument),
      options
    );
export const AdminDisconnectContextualDiscoveryCategoryLocationDocument = `
    mutation AdminDisconnectContextualDiscoveryCategoryLocation($input: AdminUpsertContextualDiscoveryCategoryLocationInput!) {
  adminDisconnectContextualDiscoveryCategoryLocation(input: $input) {
    locationId
  }
}
    `;
export const useAdminDisconnectContextualDiscoveryCategoryLocationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AdminDisconnectContextualDiscoveryCategoryLocationMutation, TError, AdminDisconnectContextualDiscoveryCategoryLocationMutationVariables, TContext>) =>
    useMutation<AdminDisconnectContextualDiscoveryCategoryLocationMutation, TError, AdminDisconnectContextualDiscoveryCategoryLocationMutationVariables, TContext>(
      ['AdminDisconnectContextualDiscoveryCategoryLocation'],
      useFetchData<AdminDisconnectContextualDiscoveryCategoryLocationMutation, AdminDisconnectContextualDiscoveryCategoryLocationMutationVariables>(AdminDisconnectContextualDiscoveryCategoryLocationDocument),
      options
    );
export const AdminToggleContextualDiscoveryActiveDocument = `
    mutation AdminToggleContextualDiscoveryActive($id: ID!) {
  adminToggleContextualDiscoveryActive(id: $id)
}
    `;
export const useAdminToggleContextualDiscoveryActiveMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AdminToggleContextualDiscoveryActiveMutation, TError, AdminToggleContextualDiscoveryActiveMutationVariables, TContext>) =>
    useMutation<AdminToggleContextualDiscoveryActiveMutation, TError, AdminToggleContextualDiscoveryActiveMutationVariables, TContext>(
      ['AdminToggleContextualDiscoveryActive'],
      useFetchData<AdminToggleContextualDiscoveryActiveMutation, AdminToggleContextualDiscoveryActiveMutationVariables>(AdminToggleContextualDiscoveryActiveDocument),
      options
    );
export const AdminUpsertContextualDiscoveryCategoryDocument = `
    mutation AdminUpsertContextualDiscoveryCategory($input: AdminUpsertContextualDiscoveryCategoryInput!) {
  adminUpsertContextualDiscoveryCategory(input: $input) {
    id
  }
}
    `;
export const useAdminUpsertContextualDiscoveryCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AdminUpsertContextualDiscoveryCategoryMutation, TError, AdminUpsertContextualDiscoveryCategoryMutationVariables, TContext>) =>
    useMutation<AdminUpsertContextualDiscoveryCategoryMutation, TError, AdminUpsertContextualDiscoveryCategoryMutationVariables, TContext>(
      ['AdminUpsertContextualDiscoveryCategory'],
      useFetchData<AdminUpsertContextualDiscoveryCategoryMutation, AdminUpsertContextualDiscoveryCategoryMutationVariables>(AdminUpsertContextualDiscoveryCategoryDocument),
      options
    );
export const AdminUpsertContextualDiscoveryCategoryLocationDocument = `
    mutation AdminUpsertContextualDiscoveryCategoryLocation($input: AdminUpsertContextualDiscoveryCategoryLocationInput!) {
  adminUpsertContextualDiscoveryCategoryLocation(input: $input) {
    locationId
  }
}
    `;
export const useAdminUpsertContextualDiscoveryCategoryLocationMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AdminUpsertContextualDiscoveryCategoryLocationMutation, TError, AdminUpsertContextualDiscoveryCategoryLocationMutationVariables, TContext>) =>
    useMutation<AdminUpsertContextualDiscoveryCategoryLocationMutation, TError, AdminUpsertContextualDiscoveryCategoryLocationMutationVariables, TContext>(
      ['AdminUpsertContextualDiscoveryCategoryLocation'],
      useFetchData<AdminUpsertContextualDiscoveryCategoryLocationMutation, AdminUpsertContextualDiscoveryCategoryLocationMutationVariables>(AdminUpsertContextualDiscoveryCategoryLocationDocument),
      options
    );
export const AdminUpsertLocationCategoryDocument = `
    mutation AdminUpsertLocationCategory($input: AdminUpsertLocationCategoryInput!) {
  adminUpsertLocationCategory(input: $input)
}
    `;
export const useAdminUpsertLocationCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AdminUpsertLocationCategoryMutation, TError, AdminUpsertLocationCategoryMutationVariables, TContext>) =>
    useMutation<AdminUpsertLocationCategoryMutation, TError, AdminUpsertLocationCategoryMutationVariables, TContext>(
      ['AdminUpsertLocationCategory'],
      useFetchData<AdminUpsertLocationCategoryMutation, AdminUpsertLocationCategoryMutationVariables>(AdminUpsertLocationCategoryDocument),
      options
    );
export const MerchantLocationCategoryDocument = `
    query MerchantLocationCategory {
  merchantLocationCategory {
    id
    name
  }
}
    `;
export const useMerchantLocationCategoryQuery = <
      TData = MerchantLocationCategoryQuery,
      TError = unknown
    >(
      variables?: MerchantLocationCategoryQueryVariables,
      options?: UseQueryOptions<MerchantLocationCategoryQuery, TError, TData>
    ) =>
    useQuery<MerchantLocationCategoryQuery, TError, TData>(
      variables === undefined ? ['MerchantLocationCategory'] : ['MerchantLocationCategory', variables],
      useFetchData<MerchantLocationCategoryQuery, MerchantLocationCategoryQueryVariables>(MerchantLocationCategoryDocument).bind(null, variables),
      options
    );
export const useInfiniteMerchantLocationCategoryQuery = <
      TData = MerchantLocationCategoryQuery,
      TError = unknown
    >(
      variables?: MerchantLocationCategoryQueryVariables,
      options?: UseInfiniteQueryOptions<MerchantLocationCategoryQuery, TError, TData>
    ) =>{
    const query = useFetchData<MerchantLocationCategoryQuery, MerchantLocationCategoryQueryVariables>(MerchantLocationCategoryDocument)
    return useInfiniteQuery<MerchantLocationCategoryQuery, TError, TData>(
      variables === undefined ? ['MerchantLocationCategory.infinite'] : ['MerchantLocationCategory.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const EmailsDocument = `
    query Emails {
  adminListEmails {
    id
    template
    subject
    name
  }
}
    `;
export const useEmailsQuery = <
      TData = EmailsQuery,
      TError = unknown
    >(
      variables?: EmailsQueryVariables,
      options?: UseQueryOptions<EmailsQuery, TError, TData>
    ) =>
    useQuery<EmailsQuery, TError, TData>(
      variables === undefined ? ['Emails'] : ['Emails', variables],
      useFetchData<EmailsQuery, EmailsQueryVariables>(EmailsDocument).bind(null, variables),
      options
    );
export const useInfiniteEmailsQuery = <
      TData = EmailsQuery,
      TError = unknown
    >(
      variables?: EmailsQueryVariables,
      options?: UseInfiniteQueryOptions<EmailsQuery, TError, TData>
    ) =>{
    const query = useFetchData<EmailsQuery, EmailsQueryVariables>(EmailsDocument)
    return useInfiniteQuery<EmailsQuery, TError, TData>(
      variables === undefined ? ['Emails.infinite'] : ['Emails.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const AdminBusinessOwnersDocument = `
    query AdminBusinessOwners($input: AdminBusinessOwnersInput!) {
  adminBusinessOwners(input: $input) {
    businessId
    ownerId
    avatar
    email
  }
}
    `;
export const useAdminBusinessOwnersQuery = <
      TData = AdminBusinessOwnersQuery,
      TError = unknown
    >(
      variables: AdminBusinessOwnersQueryVariables,
      options?: UseQueryOptions<AdminBusinessOwnersQuery, TError, TData>
    ) =>
    useQuery<AdminBusinessOwnersQuery, TError, TData>(
      ['AdminBusinessOwners', variables],
      useFetchData<AdminBusinessOwnersQuery, AdminBusinessOwnersQueryVariables>(AdminBusinessOwnersDocument).bind(null, variables),
      options
    );
export const useInfiniteAdminBusinessOwnersQuery = <
      TData = AdminBusinessOwnersQuery,
      TError = unknown
    >(
      variables: AdminBusinessOwnersQueryVariables,
      options?: UseInfiniteQueryOptions<AdminBusinessOwnersQuery, TError, TData>
    ) =>{
    const query = useFetchData<AdminBusinessOwnersQuery, AdminBusinessOwnersQueryVariables>(AdminBusinessOwnersDocument)
    return useInfiniteQuery<AdminBusinessOwnersQuery, TError, TData>(
      ['AdminBusinessOwners.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const AdminLocationDocument = `
    query AdminLocation($locationId: ID!) {
  adminLocation(locationId: $locationId) {
    location {
      id
      name
      flags
      address
      latitude
      longitude
      images {
        id
        url
        isPrimary
        size
      }
    }
    business {
      id
      name
      taxId
      regon
      owner {
        id
        email
      }
    }
  }
}
    `;
export const useAdminLocationQuery = <
      TData = AdminLocationQuery,
      TError = unknown
    >(
      variables: AdminLocationQueryVariables,
      options?: UseQueryOptions<AdminLocationQuery, TError, TData>
    ) =>
    useQuery<AdminLocationQuery, TError, TData>(
      ['AdminLocation', variables],
      useFetchData<AdminLocationQuery, AdminLocationQueryVariables>(AdminLocationDocument).bind(null, variables),
      options
    );
export const useInfiniteAdminLocationQuery = <
      TData = AdminLocationQuery,
      TError = unknown
    >(
      variables: AdminLocationQueryVariables,
      options?: UseInfiniteQueryOptions<AdminLocationQuery, TError, TData>
    ) =>{
    const query = useFetchData<AdminLocationQuery, AdminLocationQueryVariables>(AdminLocationDocument)
    return useInfiniteQuery<AdminLocationQuery, TError, TData>(
      ['AdminLocation.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const AdminLocationsDocument = `
    query AdminLocations($input: PaginatedInput) {
  adminLocations(input: $input) {
    data {
      id
      name
    }
    cursor
  }
}
    `;
export const useAdminLocationsQuery = <
      TData = AdminLocationsQuery,
      TError = unknown
    >(
      variables?: AdminLocationsQueryVariables,
      options?: UseQueryOptions<AdminLocationsQuery, TError, TData>
    ) =>
    useQuery<AdminLocationsQuery, TError, TData>(
      variables === undefined ? ['AdminLocations'] : ['AdminLocations', variables],
      useFetchData<AdminLocationsQuery, AdminLocationsQueryVariables>(AdminLocationsDocument).bind(null, variables),
      options
    );
export const useInfiniteAdminLocationsQuery = <
      TData = AdminLocationsQuery,
      TError = unknown
    >(
      variables?: AdminLocationsQueryVariables,
      options?: UseInfiniteQueryOptions<AdminLocationsQuery, TError, TData>
    ) =>{
    const query = useFetchData<AdminLocationsQuery, AdminLocationsQueryVariables>(AdminLocationsDocument)
    return useInfiniteQuery<AdminLocationsQuery, TError, TData>(
      variables === undefined ? ['AdminLocations.infinite'] : ['AdminLocations.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const AdminUpsertLocationOwnerDocument = `
    mutation AdminUpsertLocationOwner($input: AdminUpsertLocationOwnerInput!) {
  adminUpsertLocationOwner(input: $input) {
    ok
  }
}
    `;
export const useAdminUpsertLocationOwnerMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AdminUpsertLocationOwnerMutation, TError, AdminUpsertLocationOwnerMutationVariables, TContext>) =>
    useMutation<AdminUpsertLocationOwnerMutation, TError, AdminUpsertLocationOwnerMutationVariables, TContext>(
      ['AdminUpsertLocationOwner'],
      useFetchData<AdminUpsertLocationOwnerMutation, AdminUpsertLocationOwnerMutationVariables>(AdminUpsertLocationOwnerDocument),
      options
    );
export const AdminCreateMerchantDocument = `
    mutation AdminCreateMerchant($input: AdminCreateMerchantInput!) {
  adminCreateMerchant(input: $input) {
    name
    email
  }
}
    `;
export const useAdminCreateMerchantMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AdminCreateMerchantMutation, TError, AdminCreateMerchantMutationVariables, TContext>) =>
    useMutation<AdminCreateMerchantMutation, TError, AdminCreateMerchantMutationVariables, TContext>(
      ['AdminCreateMerchant'],
      useFetchData<AdminCreateMerchantMutation, AdminCreateMerchantMutationVariables>(AdminCreateMerchantDocument),
      options
    );
export const CategoryDocument = `
    query Category($categoryId: ID!) {
  category(categoryId: $categoryId) {
    id
    name
    counts {
      products
    }
    parentId
    taxId
    parent {
      id
      name
    }
    meta
    children {
      id
      name
      children {
        id
        name
      }
    }
    tree
  }
}
    `;
export const useCategoryQuery = <
      TData = CategoryQuery,
      TError = unknown
    >(
      variables: CategoryQueryVariables,
      options?: UseQueryOptions<CategoryQuery, TError, TData>
    ) =>
    useQuery<CategoryQuery, TError, TData>(
      ['Category', variables],
      useFetchData<CategoryQuery, CategoryQueryVariables>(CategoryDocument).bind(null, variables),
      options
    );
export const useInfiniteCategoryQuery = <
      TData = CategoryQuery,
      TError = unknown
    >(
      variables: CategoryQueryVariables,
      options?: UseInfiniteQueryOptions<CategoryQuery, TError, TData>
    ) =>{
    const query = useFetchData<CategoryQuery, CategoryQueryVariables>(CategoryDocument)
    return useInfiniteQuery<CategoryQuery, TError, TData>(
      ['Category.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const CreateCategoryDocument = `
    mutation CreateCategory($input: CategoryInput!) {
  createCategory(input: $input) {
    id
    name
  }
}
    `;
export const useCreateCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<CreateCategoryMutation, TError, CreateCategoryMutationVariables, TContext>) =>
    useMutation<CreateCategoryMutation, TError, CreateCategoryMutationVariables, TContext>(
      ['CreateCategory'],
      useFetchData<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument),
      options
    );
export const DeleteCategoryDocument = `
    mutation DeleteCategory($categoryId: ID!) {
  deleteCategory(categoryId: $categoryId) {
    id
  }
}
    `;
export const useDeleteCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteCategoryMutation, TError, DeleteCategoryMutationVariables, TContext>) =>
    useMutation<DeleteCategoryMutation, TError, DeleteCategoryMutationVariables, TContext>(
      ['DeleteCategory'],
      useFetchData<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument),
      options
    );
export const ProductCategoriesDocument = `
    query ProductCategories {
  productCategories {
    id
    name
    tree
    updatedAt
  }
}
    `;
export const useProductCategoriesQuery = <
      TData = ProductCategoriesQuery,
      TError = unknown
    >(
      variables?: ProductCategoriesQueryVariables,
      options?: UseQueryOptions<ProductCategoriesQuery, TError, TData>
    ) =>
    useQuery<ProductCategoriesQuery, TError, TData>(
      variables === undefined ? ['ProductCategories'] : ['ProductCategories', variables],
      useFetchData<ProductCategoriesQuery, ProductCategoriesQueryVariables>(ProductCategoriesDocument).bind(null, variables),
      options
    );
export const useInfiniteProductCategoriesQuery = <
      TData = ProductCategoriesQuery,
      TError = unknown
    >(
      variables?: ProductCategoriesQueryVariables,
      options?: UseInfiniteQueryOptions<ProductCategoriesQuery, TError, TData>
    ) =>{
    const query = useFetchData<ProductCategoriesQuery, ProductCategoriesQueryVariables>(ProductCategoriesDocument)
    return useInfiniteQuery<ProductCategoriesQuery, TError, TData>(
      variables === undefined ? ['ProductCategories.infinite'] : ['ProductCategories.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const UpdateCategoryDocument = `
    mutation UpdateCategory($category: UpdateCategoryInput!) {
  updateCategory(category: $category) {
    id
  }
}
    `;
export const useUpdateCategoryMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCategoryMutation, TError, UpdateCategoryMutationVariables, TContext>) =>
    useMutation<UpdateCategoryMutation, TError, UpdateCategoryMutationVariables, TContext>(
      ['UpdateCategory'],
      useFetchData<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument),
      options
    );
export const AdminAcceptProductSuggestionDocument = `
    mutation AdminAcceptProductSuggestion($input: AdminAcceptProductSuggestionInput!) {
  adminAcceptProductSuggestion(input: $input) {
    ok
  }
}
    `;
export const useAdminAcceptProductSuggestionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AdminAcceptProductSuggestionMutation, TError, AdminAcceptProductSuggestionMutationVariables, TContext>) =>
    useMutation<AdminAcceptProductSuggestionMutation, TError, AdminAcceptProductSuggestionMutationVariables, TContext>(
      ['AdminAcceptProductSuggestion'],
      useFetchData<AdminAcceptProductSuggestionMutation, AdminAcceptProductSuggestionMutationVariables>(AdminAcceptProductSuggestionDocument),
      options
    );
export const AdminProductToVerifyDocument = `
    query AdminProductToVerify($productSuggestionId: ID!) {
  adminProductToVerify(productSuggestionId: $productSuggestionId) {
    id
    product {
      id
      name
      ean
    }
    requester {
      email
    }
    data
    updatedAt
    createdAt
    status
    suggestions {
      id
      reason
      status
      rejectedType
      rejectedMessage
      data
      createdAt
    }
  }
}
    `;
export const useAdminProductToVerifyQuery = <
      TData = AdminProductToVerifyQuery,
      TError = unknown
    >(
      variables: AdminProductToVerifyQueryVariables,
      options?: UseQueryOptions<AdminProductToVerifyQuery, TError, TData>
    ) =>
    useQuery<AdminProductToVerifyQuery, TError, TData>(
      ['AdminProductToVerify', variables],
      useFetchData<AdminProductToVerifyQuery, AdminProductToVerifyQueryVariables>(AdminProductToVerifyDocument).bind(null, variables),
      options
    );
export const useInfiniteAdminProductToVerifyQuery = <
      TData = AdminProductToVerifyQuery,
      TError = unknown
    >(
      variables: AdminProductToVerifyQueryVariables,
      options?: UseInfiniteQueryOptions<AdminProductToVerifyQuery, TError, TData>
    ) =>{
    const query = useFetchData<AdminProductToVerifyQuery, AdminProductToVerifyQueryVariables>(AdminProductToVerifyDocument)
    return useInfiniteQuery<AdminProductToVerifyQuery, TError, TData>(
      ['AdminProductToVerify.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const AdminProductsToVerifyDocument = `
    query AdminProductsToVerify($input: AdminProductsToVerifyInput) {
  adminProductsToVerify(input: $input) {
    data {
      id
      product {
        id
        name
        ean
      }
      requester {
        email
      }
      updatedAt
      createdAt
      status
      suggestions {
        id
        reason
        status
        rejectedType
        rejectedMessage
        data
        createdAt
      }
    }
    cursor
  }
}
    `;
export const useAdminProductsToVerifyQuery = <
      TData = AdminProductsToVerifyQuery,
      TError = unknown
    >(
      variables?: AdminProductsToVerifyQueryVariables,
      options?: UseQueryOptions<AdminProductsToVerifyQuery, TError, TData>
    ) =>
    useQuery<AdminProductsToVerifyQuery, TError, TData>(
      variables === undefined ? ['AdminProductsToVerify'] : ['AdminProductsToVerify', variables],
      useFetchData<AdminProductsToVerifyQuery, AdminProductsToVerifyQueryVariables>(AdminProductsToVerifyDocument).bind(null, variables),
      options
    );
export const useInfiniteAdminProductsToVerifyQuery = <
      TData = AdminProductsToVerifyQuery,
      TError = unknown
    >(
      variables?: AdminProductsToVerifyQueryVariables,
      options?: UseInfiniteQueryOptions<AdminProductsToVerifyQuery, TError, TData>
    ) =>{
    const query = useFetchData<AdminProductsToVerifyQuery, AdminProductsToVerifyQueryVariables>(AdminProductsToVerifyDocument)
    return useInfiniteQuery<AdminProductsToVerifyQuery, TError, TData>(
      variables === undefined ? ['AdminProductsToVerify.infinite'] : ['AdminProductsToVerify.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const AdminRejectProductSuggestionDocument = `
    mutation AdminRejectProductSuggestion($productRequestUpdateId: ID!) {
  adminRejectProductSuggestion(productRequestUpdateId: $productRequestUpdateId) {
    ok
  }
}
    `;
export const useAdminRejectProductSuggestionMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AdminRejectProductSuggestionMutation, TError, AdminRejectProductSuggestionMutationVariables, TContext>) =>
    useMutation<AdminRejectProductSuggestionMutation, TError, AdminRejectProductSuggestionMutationVariables, TContext>(
      ['AdminRejectProductSuggestion'],
      useFetchData<AdminRejectProductSuggestionMutation, AdminRejectProductSuggestionMutationVariables>(AdminRejectProductSuggestionDocument),
      options
    );
export const AdminDeleteProductDocument = `
    mutation AdminDeleteProduct($input: AdminDeleteProductInput!) {
  adminDeleteProduct(input: $input) {
    id
  }
}
    `;
export const useAdminDeleteProductMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AdminDeleteProductMutation, TError, AdminDeleteProductMutationVariables, TContext>) =>
    useMutation<AdminDeleteProductMutation, TError, AdminDeleteProductMutationVariables, TContext>(
      ['AdminDeleteProduct'],
      useFetchData<AdminDeleteProductMutation, AdminDeleteProductMutationVariables>(AdminDeleteProductDocument),
      options
    );
export const AdminFindProductDuplicateDocument = `
    query AdminFindProductDuplicate($input: AdminFindProductDuplicateInput!) {
  adminFindProductDuplicate(input: $input) {
    id
    name
    ean
  }
}
    `;
export const useAdminFindProductDuplicateQuery = <
      TData = AdminFindProductDuplicateQuery,
      TError = unknown
    >(
      variables: AdminFindProductDuplicateQueryVariables,
      options?: UseQueryOptions<AdminFindProductDuplicateQuery, TError, TData>
    ) =>
    useQuery<AdminFindProductDuplicateQuery, TError, TData>(
      ['AdminFindProductDuplicate', variables],
      useFetchData<AdminFindProductDuplicateQuery, AdminFindProductDuplicateQueryVariables>(AdminFindProductDuplicateDocument).bind(null, variables),
      options
    );
export const useInfiniteAdminFindProductDuplicateQuery = <
      TData = AdminFindProductDuplicateQuery,
      TError = unknown
    >(
      variables: AdminFindProductDuplicateQueryVariables,
      options?: UseInfiniteQueryOptions<AdminFindProductDuplicateQuery, TError, TData>
    ) =>{
    const query = useFetchData<AdminFindProductDuplicateQuery, AdminFindProductDuplicateQueryVariables>(AdminFindProductDuplicateDocument)
    return useInfiniteQuery<AdminFindProductDuplicateQuery, TError, TData>(
      ['AdminFindProductDuplicate.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const AdminProductsDocument = `
    query AdminProducts($input: ProductsInput2) {
  adminProducts(input: $input) {
    data {
      id
      name
      ean
      createdAt
      updatedAt
      brandId
      brandNameId
      categoryId
      active
      images {
        url
      }
    }
    cursor
  }
}
    `;
export const useAdminProductsQuery = <
      TData = AdminProductsQuery,
      TError = unknown
    >(
      variables?: AdminProductsQueryVariables,
      options?: UseQueryOptions<AdminProductsQuery, TError, TData>
    ) =>
    useQuery<AdminProductsQuery, TError, TData>(
      variables === undefined ? ['AdminProducts'] : ['AdminProducts', variables],
      useFetchData<AdminProductsQuery, AdminProductsQueryVariables>(AdminProductsDocument).bind(null, variables),
      options
    );
export const useInfiniteAdminProductsQuery = <
      TData = AdminProductsQuery,
      TError = unknown
    >(
      variables?: AdminProductsQueryVariables,
      options?: UseInfiniteQueryOptions<AdminProductsQuery, TError, TData>
    ) =>{
    const query = useFetchData<AdminProductsQuery, AdminProductsQueryVariables>(AdminProductsDocument)
    return useInfiniteQuery<AdminProductsQuery, TError, TData>(
      variables === undefined ? ['AdminProducts.infinite'] : ['AdminProducts.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const DeleteProductImageDocument = `
    mutation DeleteProductImage($input: DeleteProductImageInput!) {
  deleteProductImage(input: $input) {
    deletedImageId
  }
}
    `;
export const useDeleteProductImageMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<DeleteProductImageMutation, TError, DeleteProductImageMutationVariables, TContext>) =>
    useMutation<DeleteProductImageMutation, TError, DeleteProductImageMutationVariables, TContext>(
      ['DeleteProductImage'],
      useFetchData<DeleteProductImageMutation, DeleteProductImageMutationVariables>(DeleteProductImageDocument),
      options
    );
export const ProductDocument = `
    query Product($filters: [ProductFilter]) {
  findProduct(filters: $filters) {
    id
    name
    ean
    description
    category {
      id
      name
    }
    brand {
      id
      name
    }
    images {
      id
      url
      isPrimary
    }
    offers {
      id
      location {
        id
        name
      }
      active
    }
  }
}
    `;
export const useProductQuery = <
      TData = ProductQuery,
      TError = unknown
    >(
      variables?: ProductQueryVariables,
      options?: UseQueryOptions<ProductQuery, TError, TData>
    ) =>
    useQuery<ProductQuery, TError, TData>(
      variables === undefined ? ['Product'] : ['Product', variables],
      useFetchData<ProductQuery, ProductQueryVariables>(ProductDocument).bind(null, variables),
      options
    );
export const useInfiniteProductQuery = <
      TData = ProductQuery,
      TError = unknown
    >(
      variables?: ProductQueryVariables,
      options?: UseInfiniteQueryOptions<ProductQuery, TError, TData>
    ) =>{
    const query = useFetchData<ProductQuery, ProductQueryVariables>(ProductDocument)
    return useInfiniteQuery<ProductQuery, TError, TData>(
      variables === undefined ? ['Product.infinite'] : ['Product.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const ProductsDocument = `
    query Products($input: ProductsInput2) {
  products(input: $input) {
    data {
      id
      name
      ean
      createdAt
      updatedAt
      brandId
      brandNameId
      categoryId
      active
      images {
        url
      }
    }
    cursor
  }
}
    `;
export const useProductsQuery = <
      TData = ProductsQuery,
      TError = unknown
    >(
      variables?: ProductsQueryVariables,
      options?: UseQueryOptions<ProductsQuery, TError, TData>
    ) =>
    useQuery<ProductsQuery, TError, TData>(
      variables === undefined ? ['Products'] : ['Products', variables],
      useFetchData<ProductsQuery, ProductsQueryVariables>(ProductsDocument).bind(null, variables),
      options
    );
export const useInfiniteProductsQuery = <
      TData = ProductsQuery,
      TError = unknown
    >(
      variables?: ProductsQueryVariables,
      options?: UseInfiniteQueryOptions<ProductsQuery, TError, TData>
    ) =>{
    const query = useFetchData<ProductsQuery, ProductsQueryVariables>(ProductsDocument)
    return useInfiniteQuery<ProductsQuery, TError, TData>(
      variables === undefined ? ['Products.infinite'] : ['Products.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const TryToCreateProductFromOnlineDocument = `
    mutation TryToCreateProductFromOnline($gtin: String!) {
  tryToCreateProductFromOnline(gtin: $gtin) {
    id
    name
    ean
    brandId
    categoryId
    description
    images {
      id
      url
    }
    existing
  }
}
    `;
export const useTryToCreateProductFromOnlineMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<TryToCreateProductFromOnlineMutation, TError, TryToCreateProductFromOnlineMutationVariables, TContext>) =>
    useMutation<TryToCreateProductFromOnlineMutation, TError, TryToCreateProductFromOnlineMutationVariables, TContext>(
      ['TryToCreateProductFromOnline'],
      useFetchData<TryToCreateProductFromOnlineMutation, TryToCreateProductFromOnlineMutationVariables>(TryToCreateProductFromOnlineDocument),
      options
    );
export const TryToFindProductOnlineDocument = `
    query TryToFindProductOnline($input: TryToFindProductOnlineInput) {
  tryToFindProductOnline(input: $input)
}
    `;
export const useTryToFindProductOnlineQuery = <
      TData = TryToFindProductOnlineQuery,
      TError = unknown
    >(
      variables?: TryToFindProductOnlineQueryVariables,
      options?: UseQueryOptions<TryToFindProductOnlineQuery, TError, TData>
    ) =>
    useQuery<TryToFindProductOnlineQuery, TError, TData>(
      variables === undefined ? ['TryToFindProductOnline'] : ['TryToFindProductOnline', variables],
      useFetchData<TryToFindProductOnlineQuery, TryToFindProductOnlineQueryVariables>(TryToFindProductOnlineDocument).bind(null, variables),
      options
    );
export const useInfiniteTryToFindProductOnlineQuery = <
      TData = TryToFindProductOnlineQuery,
      TError = unknown
    >(
      variables?: TryToFindProductOnlineQueryVariables,
      options?: UseInfiniteQueryOptions<TryToFindProductOnlineQuery, TError, TData>
    ) =>{
    const query = useFetchData<TryToFindProductOnlineQuery, TryToFindProductOnlineQueryVariables>(TryToFindProductOnlineDocument)
    return useInfiniteQuery<TryToFindProductOnlineQuery, TError, TData>(
      variables === undefined ? ['TryToFindProductOnline.infinite'] : ['TryToFindProductOnline.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const UpdateProductDocument = `
    mutation UpdateProduct($input: UpdateProductInput!) {
  updateProduct(input: $input) {
    id
  }
}
    `;
export const useUpdateProductMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateProductMutation, TError, UpdateProductMutationVariables, TContext>) =>
    useMutation<UpdateProductMutation, TError, UpdateProductMutationVariables, TContext>(
      ['UpdateProduct'],
      useFetchData<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument),
      options
    );
export const UpsertProductDocument = `
    mutation UpsertProduct($input: UpsertProductInput!) {
  upsertProduct(input: $input) {
    id
  }
}
    `;
export const useUpsertProductMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<UpsertProductMutation, TError, UpsertProductMutationVariables, TContext>) =>
    useMutation<UpsertProductMutation, TError, UpsertProductMutationVariables, TContext>(
      ['UpsertProduct'],
      useFetchData<UpsertProductMutation, UpsertProductMutationVariables>(UpsertProductDocument),
      options
    );
export const AdminAddTaxDocument = `
    mutation AdminAddTax($input: AddTaxInput!) {
  adminAddTax(input: $input) {
    id
    rate
    description
  }
}
    `;
export const useAdminAddTaxMutation = <
      TError = unknown,
      TContext = unknown
    >(options?: UseMutationOptions<AdminAddTaxMutation, TError, AdminAddTaxMutationVariables, TContext>) =>
    useMutation<AdminAddTaxMutation, TError, AdminAddTaxMutationVariables, TContext>(
      ['AdminAddTax'],
      useFetchData<AdminAddTaxMutation, AdminAddTaxMutationVariables>(AdminAddTaxDocument),
      options
    );
export const AdminTaxesDocument = `
    query AdminTaxes($input: PaginatedInput) {
  adminTaxes(input: $input) {
    data {
      id
      rate
      description
      createdAt
      updatedAt
    }
    cursor
  }
}
    `;
export const useAdminTaxesQuery = <
      TData = AdminTaxesQuery,
      TError = unknown
    >(
      variables?: AdminTaxesQueryVariables,
      options?: UseQueryOptions<AdminTaxesQuery, TError, TData>
    ) =>
    useQuery<AdminTaxesQuery, TError, TData>(
      variables === undefined ? ['AdminTaxes'] : ['AdminTaxes', variables],
      useFetchData<AdminTaxesQuery, AdminTaxesQueryVariables>(AdminTaxesDocument).bind(null, variables),
      options
    );
export const useInfiniteAdminTaxesQuery = <
      TData = AdminTaxesQuery,
      TError = unknown
    >(
      variables?: AdminTaxesQueryVariables,
      options?: UseInfiniteQueryOptions<AdminTaxesQuery, TError, TData>
    ) =>{
    const query = useFetchData<AdminTaxesQuery, AdminTaxesQueryVariables>(AdminTaxesDocument)
    return useInfiniteQuery<AdminTaxesQuery, TError, TData>(
      variables === undefined ? ['AdminTaxes.infinite'] : ['AdminTaxes.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const AdminUserDocument = `
    query AdminUser($id: ID!) {
  adminUser(id: $id) {
    id
    avatar
    phoneNumber
    email
    stripeCustomerId
    role
    businessId
    createdAt
    updatedAt
  }
}
    `;
export const useAdminUserQuery = <
      TData = AdminUserQuery,
      TError = unknown
    >(
      variables: AdminUserQueryVariables,
      options?: UseQueryOptions<AdminUserQuery, TError, TData>
    ) =>
    useQuery<AdminUserQuery, TError, TData>(
      ['AdminUser', variables],
      useFetchData<AdminUserQuery, AdminUserQueryVariables>(AdminUserDocument).bind(null, variables),
      options
    );
export const useInfiniteAdminUserQuery = <
      TData = AdminUserQuery,
      TError = unknown
    >(
      variables: AdminUserQueryVariables,
      options?: UseInfiniteQueryOptions<AdminUserQuery, TError, TData>
    ) =>{
    const query = useFetchData<AdminUserQuery, AdminUserQueryVariables>(AdminUserDocument)
    return useInfiniteQuery<AdminUserQuery, TError, TData>(
      ['AdminUser.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export const AdminUsersDocument = `
    query AdminUsers($input: PaginatedInput!) {
  adminUsers(input: $input) {
    data {
      id
      avatar
      phoneNumber
      email
      stripeCustomerId
      role
      businessId
      createdAt
      updatedAt
    }
    cursor
  }
}
    `;
export const useAdminUsersQuery = <
      TData = AdminUsersQuery,
      TError = unknown
    >(
      variables: AdminUsersQueryVariables,
      options?: UseQueryOptions<AdminUsersQuery, TError, TData>
    ) =>
    useQuery<AdminUsersQuery, TError, TData>(
      ['AdminUsers', variables],
      useFetchData<AdminUsersQuery, AdminUsersQueryVariables>(AdminUsersDocument).bind(null, variables),
      options
    );
export const useInfiniteAdminUsersQuery = <
      TData = AdminUsersQuery,
      TError = unknown
    >(
      variables: AdminUsersQueryVariables,
      options?: UseInfiniteQueryOptions<AdminUsersQuery, TError, TData>
    ) =>{
    const query = useFetchData<AdminUsersQuery, AdminUsersQueryVariables>(AdminUsersDocument)
    return useInfiniteQuery<AdminUsersQuery, TError, TData>(
      ['AdminUsers.infinite', variables],
      (metaData) => query({...variables, ...(metaData.pageParam ?? {})}),
      options
    )};

export type BigInt = Scalars["BigInt"];
export type DateTime = Scalars["DateTime"];
export type File = Scalars["File"];
export type Json = Scalars["Json"];
export type Upload = Scalars["Upload"];