import { Group, Text, Tooltip } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconInfoCircle } from "@tabler/icons-react";
import * as React from "react";

import { BarcodeScannerInput } from "../../../components/BarcodeScannerInput/BarcodeScannerInput";
import {
  useTryToCreateProductFromOnlineMutation,
  useUpsertProductMutation,
} from "../../graphql/graphql";

export const ProductsNewSerialPage = () => {
  const [scannedProductsFoundOnline, setScannedProductsFoundOnline] =
    React.useState([]);
  const [scannedProductsEmpty, setScannedProductsEmpty] = React.useState([]);

  const { mutateAsync: tryToCreateProductFromGtin } =
    useTryToCreateProductFromOnlineMutation();

  const { mutateAsync: createProductMutationAsync } =
    useUpsertProductMutation();

  const allScannedEans = [
    ...scannedProductsFoundOnline.map((scan) => scan.ean),
    ...scannedProductsEmpty.map((scan) => scan.ean),
  ];

  return (
    <div style={{ padding: "0 8px" }}>
      <BarcodeScannerInput
        onChange={async (ean) => {
          const isAlreadyScaned = allScannedEans.find(
            (scannedEan) => scannedEan === ean,
          );
          if (isAlreadyScaned) {
            notifications.show({
              variant: "warning",
              title: "Zeskanowano już ten kod",
              message: `Kod kreskowy ${ean} został już zeskanowany.`,
            });
            return;
          }

          const { tryToCreateProductFromOnline: productOnline } =
            await tryToCreateProductFromGtin({
              gtin: ean,
            });

          if (productOnline) {
            setScannedProductsFoundOnline((prev) => [...prev, productOnline]);
          }

          if (!productOnline) {
            const draft = {
              ean,
              name: "Brak nazwy",
              description: "Brak opisu",
            };
            await createProductMutationAsync({
              input: {
                ...draft,
                images: [],
              },
            });
            setScannedProductsEmpty((prev) => [...prev, draft]);
          }
        }}
      />
      <Group grow>
        <div>
          <Text className="text-center">Produkty znalezione online</Text>
          <ul>
            {scannedProductsFoundOnline.map((scan) => (
              <li key={scan.id}>
                {scan.existing ? (
                  <Tooltip label="Produkt nie został dodany do bazy. Istnieje juz w bazie">
                    <IconInfoCircle />
                  </Tooltip>
                ) : null}
                {scan.name}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <Text className="text-center">Szkice produktów</Text>
          <ul>
            {scannedProductsEmpty.map((scan) => (
              <li key={scan.ean}>{scan.ean}</li>
            ))}
          </ul>
        </div>
      </Group>
    </div>
  );
};
